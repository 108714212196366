<div class="container">
  <div class="column1">
    <div
      *ngFor="let tab of tabs; let i = index"
      (click)="toggleTabs(i + 1)"
      class="mail-description"
      [class.selected]="selectedTab === i + 1"
    >
      {{ tab }}
    </div>
  </div>
  <div class="column2">
    <div *ngIf="selectedTab === 1" class="mail-content">
      <app-attribute-creation></app-attribute-creation>
    </div>
    <!-- <div *ngIf="selectedTab === 2" class="mail-content">
      <app-attribute-creation></app-attribute-creation>
    </div> -->
  </div>
</div>
