import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
  UrlTree,
} from '@angular/router';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class AdminGuard {
  public path: string = '';
  constructor(
    private location: Location,
    private router: Router,
    private loginService: LoginService
  ) { }
  CanActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    return new Promise((resolve, reject) => {
      let userDetails: any = localStorage.getItem('user_details')
        ? JSON.parse(localStorage.getItem('user_details') || '')
        : '';
      if (!userDetails.email) {
        this.loginService.removekeys();
        resolve(false);
      }
      this.loginService.isTokenValid().subscribe({
          next: (res: any) => {
            console.log('Res ******', res);
            try {
              if (!res.success) {
                this.loginService.removekeys();
                this.loginService.tokenValidation({
                  status: 401,
                  message: 'Session Expired'
                })
                resolve(false);
              } else {
                resolve(true);
              }
            } catch (error) {
              this.loginService.removekeys();
              this.loginService.tokenValidation({
                status: 401,
                message: 'Session Expired'
              })
              resolve(false);
            }
          },
          error: (err: any) => {
            if(err.status === 401){
              this.loginService.removekeys();
              this.loginService.tokenValidation({
                status: 401,
                message: 'Session Expired'
              })
              resolve(false);
            }
          }
        }
      );
    });
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  return inject(AdminGuard).CanActivate(route, state);
};
