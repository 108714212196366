import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Eglobal, table } from '@enum';
import * as _ from 'lodash';
import { GlobalServicesService } from '../services/global-service.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent {
  @Input() columns: any;
  @Input() dataList: any;
  @Input() dataCount: any;
  @Input() totalPage: any;
  @Input() tableFilter: any;
  @Input() eventListen: boolean = true;
  @Input() allRowsSelected: any;
  @Input() isRowsSelectedDisplay: boolean = true;
  @Input() pageLimits: any;
  @Input() pageAndRow: any;
  @Input() action: any;
  @Input() download: any;
  @Input() columnList: any;
  @Input() actionOptions: any;
  @Input() filterData!: { [key: string]: any[] };
  @Input() checkBox!: boolean;
  @ViewChild('overlayPanel') overlaypanel: any;
  @Output() openEdit = new EventEmitter<any>();
  @Output() deletingData = new EventEmitter<any>();
  @Output() applyFilters = new EventEmitter<any>();
  @Output() pageNo = new EventEmitter<any>();
  @Output() openSideBar = new EventEmitter<any>();
  @Output() search = new EventEmitter<any>();
  @Output() selectedColumns = new EventEmitter<any>();
  @Output() rowLength = new EventEmitter<any>();
  @Output() fileFormat = new EventEmitter<any>();
  @Output() checkedRowsLength = new EventEmitter<any>();
  @Output() onRowClick = new EventEmitter<any>();
  @Output() handleRedirect = new EventEmitter<any>();
  @Input() tableheight: string = '75vh';
  @Input() remValue: string = '18';
  isPreviousButtonDisabled: boolean = false;
  isNextButtonDisabled: boolean = false;
  currentPage: number = 1;
  searchValue: any = '';
  searchColor: boolean = true;
  selectedProduct: any;
  pageSize: number = 10;
  showFilter: boolean = false;
  showList: boolean = false;
  showDownload: boolean = false;
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  indexnumber: number = 1;
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  selectedAction: any;
  openEditSidebar: boolean = false;
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  tableEnum: any = table;
  globalEnum: any = Eglobal;
  downloadColumnSelectAll: boolean = false
  constructor(private globalService: GlobalServicesService,private cdr: ChangeDetectorRef,) {}

  ngOnInit() {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateTableHeight();
  }
  private calculateTableHeight() {
    const remInPx = 16; // Assuming the base font size is 16px
    const remValueInNumber = parseFloat(this.remValue); // Convert remValue string to number
    const remValueInPx = remValueInNumber * remInPx;
    const windowHeight = window.innerHeight;
    const heightInPx = windowHeight - remValueInPx;

    this.tableheight = `${heightInPx}px`;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.calculateTableHeight();
    this.cdr.detectChanges()
  }
  ngOnDestroy(){
    this.selectedOptions = []
  }

  ngOnChanges(_changes: SimpleChanges){
    if(_changes["eventListen"]){
      this.selectedOptions = [];
    }
    
  }

  onApiResponse() {
    for (const row of this.dataList) {
      row.selected = false;
    }
    this.checkedAll = false;
    this.selectedOptions = [];
    this.selectedFilterOptions = {};
  }

  handleButtonClick(event: any) {
    this.handleRedirect.emit(event); 
  }

  onSearchChange(event: any) {
    let searchValue = event.target.value;
    this.searchColor = searchValue.length > 0 ? false : true;

    if (searchValue.length === 0) {
      this.search.emit('');
    }
  }

  inputEnter(event: any) {
    this.search.emit(this.searchValue);
  }

  rowLimit(event: any) {
    this.rowLength.emit(event.value);
  }
  openlist(name: string) {
    this.closeFilters();
    if(name === this.leftSidebarName) {
      this.leftSidebarName = '';
      return;
    }
    this.showList = true;
    this.leftSidebarName = name;
    if (this.showFilter) {
      this.showFilter = !this.showFilter;
    }
  }
  closeFilters() {
    this.showFilter = false;
    this.showList = false;
    this.downloadColumnSelectAll = false
  }
  openDownload(name: string) {
    if(name === this.leftSidebarName) {
      this.closeFilters();
      this.leftSidebarName = '';
      return;
    }
    _.forEach(this.columns, (column: any) => {
      column.selected = false;
    });
    this.showList = true;
    this.leftSidebarName = name;
    if (this.showFilter) {
      this.showFilter = !this.showFilter;
    }
  }
  openFilter() {
    this.closeFilters();
    this.showFilter = true
  }
  onHidevent(event: any) {}

  checkAll(event: any) {
    this.selectedOptions = [];
    for (const q of this.dataList) {
      q.selected = event.checked;
      if (event.checked) {
        this.selectedOptions.push(q);
      }
    }
    this.checkedAll = event.checked;
    this.isRowsSelectedDisplay = this.selectedOptions.length > 0 ? true : false;
  }

  checkedrow(event: any) {
    if (event.selected) {
      this.selectedOptions.push(event);
    } else {
      const i = this.selectedOptions.indexOf(event);
      if (i !== -1) {
        this.selectedOptions.splice(i, 1);
      }
    }
    this.checkedAll = this.selectedOptions.length === this.dataList.length;
    this.isRowsSelectedDisplay = this.selectedOptions.length > 0 ? true : false;
    this.checkedRowsLength.emit(this.selectedOptions);
  }

  onMouseEnter(columnField: string) {
    this.sortIconVisible[columnField] = true;
  }

  onMouseLeave(columnField: string) {
    this.sortIconVisible[columnField] = false;
  }
  checkColumnName(event: any) {
    if (event.selected) {
      this.checkedColumns.push(event);
    } else {
      const i = this.checkedColumns.indexOf(event);
      if (i !== -1) {
        this.checkedColumns.splice(i, 1);
      }
    }
  }
  changeTableDefault(event: any, column: any) {
  }
  downloadColumnsAs() {
    this.fileFormat.emit(this.downloadColumnAs);
    this.selectedColumns.emit(this.checkedColumns);
  }
  checkFilterOptions(event: any, key: any, options: any, data: any) {
    if (event.checked) {
      options.selected = event.checked;
      this.selectedFilterOptions[key] = this.selectedFilterOptions[key] || [];
      this.selectedFilterOptions[key].push(options.value);
    } else {
      this.selectedFilterOptions[key] = this.selectedFilterOptions[key].filter(
        (arr: any) => arr !== options.value
      );
      if (this.selectedFilterOptions[key].length === 0) {
        delete this.selectedFilterOptions[key];
      }
    }
    options.selected = event.checked;
  }

  cancelFilterOptions() {
    this.showFilter = false;
    for (const key in this.filterData) {
      if (this.selectedFilterOptions.hasOwnProperty(key)) {
        this.filterData[key].forEach((element: any) => {
          element.selected = false;
        });
      }
    }
    this.selectedFilterOptions = {};
  }

  onClickAction(option: any) {
    this.overlaypanel.hide();
    option.value === 'edit'
      ? this.openEditFiled()
      : option.value === 'delete'
      ? this.deleteData()
      : this.showHistory();
  }

  openEditFiled() {
    this.openEdit.emit(this.selectedOptions)
  }

  onSelectedEventChange(event: any){
    this.openSideBar.emit([event])
  }

  previousPagination(event: any) {
    if (this.currentPage == 1) {
      this.isPreviousButtonDisabled = true;
      return;
    } else {
      this.isPreviousButtonDisabled = false;
    }
    this.currentPage = this.currentPage - 1;
    this.pageNo.emit(this.currentPage);
  }
  nextPagination(event: any) {
    if (this.currentPage == this.totalPage) {
      this.isNextButtonDisabled = true;
      return;
    } else {
      this.isNextButtonDisabled = false;
    }
    this.currentPage = this.currentPage + 1;
    this.pageNo.emit(this.currentPage);
  }

  onEditFields() {}
  deleteData() {
    this.deletingData.emit(this.selectedOptions);
  }

  showHistory() {}

  saveBulkUpload() {}
  filtersApply() {
    this.showFilter = false;
    this.applyFilters.emit(this.selectedFilterOptions);
  }
  searchValueChange(event: any) {
    this.searchValue = event.target.value;
  }

  rowClick(event: any){
    this.onRowClick.emit(event)
  }
  downloadColumnAll(event: any) {
    if (event.checked) {
      this.columns.forEach((element: { selected: boolean; }) => {
        element.selected = true
        this.checkedColumns.push(element)
      });
    }
    else {
      this.columns.forEach((element: { selected: boolean; }) => {
        element.selected = false
        this.checkedColumns = []
      });
    }
  }
}
