<div class="programme-offered-cont">
  <div class="button-cont">
    <button
      pButton
      pRipple
      class="p-button-create blue-filled-btn"
      (click)="createNew()"
      label="Create new"
    >
    </button>
    <p-sidebar [(visible)]="showBulkUpload" position="right">
      <ng-template pTemplate="header">
        <!-- <div class="header-cont">
          <span>Bulk Upload</span>
          <button
            pbutton
            class="p-button-create blue-filled-btn"
            (click)="saveBulkUpload()"
            type="submit"
          >
            Save & Next
          </button>
        </div> -->
        <p-divider layout="horizontal"></p-divider>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="bulkUpload-cnt">
          <div class="download-excel">
            <span class="download-header">Dowload Bulk Upload Sample</span>
            <div class="download-body">
              <div class="csv-icon">
                <div class="icon">
                  <img src="../assets/programme-offered/csv.svg" alt="" />
                </div>
                <span>Template_Data.csv</span>
              </div>
              <div class="download-icon"><i class="pi pi-download"></i></div>
            </div>
          </div>
          <div class="upload-excel">
            <span class="download-header">Upload Bulk Mapping File </span>
            <div class="upload-body">
              <div class="download-icon"><i class="pi pi-upload"></i></div>
              <div class="upload-texts">
                <span class="bold-text"
                  >Drag & Drop Your files or Choose file</span
                >
                <span class="normal-text">.XLS,.XLSX,.CSV Formate only</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="createSidebarShow" position="right">
      <ng-template pTemplate="header">
        <div class="header-cont">
          <span>{{ sideBarOperation }}</span>
          <button
            (click)="
              sideBarButton === 'create' ? onSubmitCreate() : onEditFields()
            "
            pbutton
            class="p-button-create blue-filled-btn"
            type="submit"
          >
            {{ sideBarButton }}
          </button>
        </div>
        <p-divider layout="horizontal"></p-divider>
      </ng-template>
      <ng-template pTemplate="body">
        <form
          [formGroup]="createNewField.valid ? patchedValues : createNewField"
          class="create-fields"
          (ngSubmit)="onSubmitCreate()"
        >
          <span class="p-float-label create-fields fields">
            <input
              type="text"
              class="fields"
              pInputText
              formControlName="name"
            />
            <label for="field_name">Field Name</label>
          </span>
          <span class="p-float-label create-fields fields">
            <p-chips
              separator=","
              formControlName="FieldTypes"
              styleClass="fields"
            ></p-chips>
            <label for="field_name">Field Types</label>
          </span>
        </form>
      </ng-template>
    </p-sidebar>
  </div>
  <div class="table-cont table-relative">
    <app-table
      [columns]="columns"
      [dataList]="dataList"
      [checkBox]="true"
      [pageLimits]="pageLimits"
      [actionOptions]="actionOptions"
      (openEdit)="openEditFiled($event)"
      (deletingData)="deleteData($event)"
      [action]="true"
    ></app-table>
    <p-toast></p-toast>
  </div>
</div>
