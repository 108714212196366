import { GlobalDataComponent } from './../global-data/global-data.component';
import { SuperAdminService } from '../services/super-admin.service';
import { ChangeDetectorRef, Component, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FileUploadService } from '../services/fileupload.service';
import { GlobalServicesService } from '../services/global-service.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent {
  @ViewChild('overlayPanel') overlaypanel: any;
  searchValue: any = ''
  columns: any[] = [];
  dataList: any[] = [];
  rowsize: number = 10;
  fieldSelectedForDownload: any[] = [];
  selectedFields: any = [];
  isLoading: boolean = false
  showAction: boolean = true;
  dataCount: number = 0;
  totalPage: number = 0;
  numericPattern = /^\d+$/;
  selectedPage: any = 1;
  searchColor: boolean = true;
  createSidebarShow: boolean = false;
  currentCompanyId: string = ""
  pageSize: number = 10;
  pageLimits: any[] = [];
  showFilter: boolean = false;
  showList: boolean = false;
  showDownload: boolean = false;
  inputData: any[] = [];
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  createNewField: FormGroup = new FormGroup({});
  formValid: boolean = false;
  pageAndRow: boolean = true
  indexnumber: number = 1
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  actionOptions: any[] = [];
  selectedAction: any;
  openEditSidebar: boolean = false
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  isFileRemoved: boolean = true;
  filePath: any;
  showCreateReport: boolean = false;
  updatedCount: number = 0;
  listenChange: boolean = true;
  totalCount: number = 0;
  updateErrorCount: number = 0;
  updateErrorData: any[] = [];
  createdCount: number = 0;
  createErrorCount: number = 0;
  createErrorData: any[] = [];
  mediaFile: File[] = [];
  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
    actionId: new FormControl('')
  })
  excel: any;
  uploadingFile: boolean = false;
  bucketData: any = {};
  fileBase64: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  sampleDataForOnboard: any[] = [];



  constructor(private cdr: ChangeDetectorRef, private messageService: MessageService, private superAdminService: SuperAdminService, private globalService: GlobalServicesService, private fileUploadService: FileUploadService, private http: HttpService) {
    this.pageLimits = [{ label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    ]
    this.columns = [
      { field: 'company_name', header: 'Company Name', sortable: true, freezable: false, tableDefault: true,width: '50%' },
      { field: 'company_url', header: 'Company URL', sortable: true, freezable: false, tableDefault: true,width: '50%' },
    ];

    this.inputData = [
      { id: 1, label: 'Company Name', type: 'text', controlfield: 'company_name', fieldtype: 'input', mandatory: true },
      { id: 1, label: 'Company URL', type: 'text', controlfield: 'company_url', fieldtype: 'input', mandatory: true },
    ];
    this.actionOptions = [{ label: 'Edit', value: 'edit' }]
    this.searchValue = '';
    this.sampleDataForOnboard = [{ 'Company Name': "iamneo", 'Company URL': "iamneo.ai" }];

  }

  async ngOnInit() {
    this.createNewField = this.createFormGroupFromInputData();
    const body = { "limit": this.rowsize, "page": this.selectedPage, };
    this.isLoading = true;
    const response = await this.fetchCompanies(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    this.isLoading = true;
    const response = await this.fetchCompanies(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return
  }

  isChecked(label: any) {
    if (label.selected) {
      this.selectedFields.push(label.field);
    } else {
      const fieldIndex = this.selectedFields.indexOf(label.field);
      if (fieldIndex !== -1) {
        this.selectedFields.splice(fieldIndex, 1);
      }
    }
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    this.isLoading = true;
    const response = await this.fetchCompanies(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    this.isLoading = true;
    const response = await this.fetchCompanies(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return
  }

  async onSubmitCreate() {
    this.formValid = this.createNewField.valid
    const payload: any = {};
    if (!this.formValid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill all the mandatory Fields' })
      return;
    }
    const obj: any = await this.assignAndValidation();
    payload.company_name = obj.company_name;
    payload.company_url = obj.company_url;
    this.isLoading = true;
    const invite = await this.companyImport(payload);
    this.createSidebarShow = !this.createSidebarShow;
    if (invite && invite.success) {
      const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
      const response = await this.fetchCompanies(body);
      if (response.success) {
        this.dataList = response.data.data;
        this.dataCount = response.data.count;
        this.totalPage = Math.ceil(this.dataCount / this.rowsize);
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: invite.data })
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: invite.message })
    }
    this.isLoading = false;
    return;
  }

  assignAndValidation(payload?: any) {
    let obj: any = {}
    for (const controlField in this.createNewField.controls) {
      obj[controlField] = this.createNewField.controls[controlField].value;
    }
    return (obj);
  }

  async deleteData(event: any) {
    const company_id = _.map(event, 'company_id');
    this.isLoading = true;
    const bodyForDelete = { company_id: company_id };
    const deleteResponse = await this.deleteCompanies(bodyForDelete);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' })
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    const response = await this.fetchCompanies(body);
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    this.checkedAll = false;
    this.isLoading = false;
  }

  async fetchCompanies(body: any) {
    const fetchStudentsDetails = await this.http.post('/companies/fetch', body);
    return fetchStudentsDetails;
  }

  async companyImport(body: any) {
    const fetchProgrammeDetails = await this.http.post('/companies/import', body);
    return fetchProgrammeDetails;
  }

  async companyUpdate(body: any) {
    const fetchStudentsDetails = await this.http.post('/companies/update', body);
    return fetchStudentsDetails;
  }

  async deleteCompanies(body: any) {
    const fetchProgrammeDetails = await this.http.post('/companies/delete', body);
    return fetchProgrammeDetails;
  }

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach(data => {
      formGroupConfig[data.controlfield] = new FormControl('', data.mandatory ? Validators.required : null);
    });
    return new FormGroup(formGroupConfig);
  }

  bulkUpload() {
    this.showBulkUpload = true
  }

  createNew() {
    this.createNewField.reset();
    this.selectedOptions = []
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
  }

  fileFormat(event: any) {
    this.downloadColumnAs = event;
  }

  async selectedField(event: any) {
    this.fieldSelectedForDownload = _.map(event, 'field');
    this.isLoading = true;
    const body = { "page": this.selectedPage, "limit": this.rowsize, fields: this.fieldSelectedForDownload };
    const response = await this.fetchCompanies(body);
    this.isLoading = false;
    this.downloadFile(response.data, this.downloadColumnAs, "Companies-list");
  }

  downloadFile(response: any, fileType: any, fileName: any) {
    const worksheet = XLSX.utils.json_to_sheet(response);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: fileType === 'excel' ? 'xlsx' : 'csv', type: 'array', });
    this.saveAsExcelFile(excelBuffer, fileName, fileType);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE = type === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], { type: FILE_TYPE, });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  openEditFiled(event: any) {
    this.currentCompanyId = '';
    this.selectedOptions = _.cloneDeep(event)
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 2) {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Validate',
          detail: 'Select only one row'
        }
      )
      return;
    }
    this.createNewField.patchValue(this.selectedOptions[0])
    this.currentCompanyId = this.selectedOptions[0].company_id
    this.patchedValues = _.cloneDeep(this.createNewField)
    this.createSidebarShow = true
  }

  async onEditFields() {
    let changedFields: any = {};
    const payload: any = {};
    for (const controlField in this.createNewField.controls) {
      changedFields[controlField] = this.createNewField.controls[controlField].value;
    }
    if (Object.keys(changedFields).length === 0) {
      this.messageService.add({ severity: 'error', summary: 'Validate', detail: 'Fields are Unchanged' })
      this.createSidebarShow = !this.createSidebarShow
      return;
    }
    if (this.createNewField.valid) {
      const obj: any = await this.assignAndValidation();
      payload.company_id = this.currentCompanyId;
      payload.company_name = obj.company_name;
      payload.company_url = obj.company_url;
      this.isLoading = true;
      const update = await this.companyUpdate(payload);
      this.createSidebarShow = !this.createSidebarShow;
      if (update && update.success) {
        const body = { "limit": this.rowsize, "page": this.selectedPage };
        const response = await this.fetchCompanies(body);
        if (response.success) {
          this.dataList = response.data.data;
          this.dataCount = response.data.count;
          this.totalPage = Math.ceil(this.dataCount / this.rowsize);
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: update.data })
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: update.message })
      }
      this.createNewField = this.createFormGroupFromInputData();
      this.patchedValues = _.cloneDeep(this.createNewField);
      this.isLoading = false;
      // this.isLoading = true;
      this.listenChange = false;
      return;
    }
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Fill the mandatory fields' })
  }

  showHistory() {

  }

  saveBulkUpload() {

  }
  async saveBulkUpdate(type: any) {
    if (!this.isFileRemoved) {
      let body = { file: this.filePath };
      let endPoint = "/companies/bulkImport"
      this.isLoading = true;
      const response = await this.http.post(endPoint, body);
      if (response && response.success) {
        this.showCreateReport = true;
        this.createdCount = response.data.successLength;
        this.totalCount = response.data.totalRecord;
        this.createErrorCount = response.data.errorCount;
        this.createErrorData = response.data.errorDatas;
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Validate', detail: response.message })
      }
      this.isLoading = false;
    } else {
      this.messageService.add({ severity: 'error', summary: 'Validate', detail: 'Please upload the file' })
    }
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.bulkInviteForm.controls['file'].setValue('');
    this.isFileRemoved = true;
  }

  onSelectExcel(event: any, type: any) {
    this.excel = event.addedFiles;
    this.mediaFile.push(...event.addedFiles);
    this.isFileRemoved = false;
    this.handleUpload(type);
  }

  handleUpload(type: any) {
    const file = this.excel[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, type);
    }; 
  }

  async fileUpload(file: any, type: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, '').replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    this.filePath = `superadmin/companies/${type}/${fileName}`;
    this.bucketData = { bucketName: this.globalService.bucketName, fileName: this.filePath, type: file.type };
    const blobData: any = file;

    let url: any = await this.fileUploadService.postApiCall(
      '/getSignedUrl',
      this.bucketData
    );
    if (url && url.data) {
      const json = url;
      console.log(json.data);
      await this.fileUploadService.uploadUsingSignedUrl(
        json.data,
        blobData
      );
      this.fileBase64 = `${this.globalService.prefix}/${this.globalService.bucketName}/${this.bucketData.fileName}`;
      console.log(this.fileBase64);
      this.uploadingFile = false;
    }
  }

  downloadReport(type: any) {
    if (type == "create") {
      this.downloadFile(this.createErrorData, "excel", "Company-details-create-error");
    }
  }

  sampleData(type: any) {
    if (type == "create") {
      this.downloadFile(this.sampleDataForOnboard, "excel", "Company-details-sample");
    }
  }
}
