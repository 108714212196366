import {
  Component,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalServicesService } from '../services/global-service.service';
import { FileUploadService } from 'src/app/services/fileupload.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { SuperAdminService } from '../services/super-admin.service';
import { MessageService } from 'primeng/api';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.css'],
})
export class AccountCreateComponent {
  @Input() createSidebarShow: any;
  @Input() patchValue: FormGroup | any;
  @Output() saveButtonClick = new EventEmitter<void>();
  @Output() closeSideBar = new EventEmitter<any>();
  @Output() openEdit = new EventEmitter<any>();

  columns: any[] = [];
  editInput: boolean = true;
  numericPattern = /^\d+$/;
  selectedOptions: any[] = [];
  inputData: any[] = [];
  accountsData: any = [];
  campusDetails: any[] = [];
  patchedValues: any = {};
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  phoneNumber: any = /^[0-9]+$/
  createNewField: FormGroup = new FormGroup({});
  // createSidebarShow: boolean = false;
  formValid: boolean = false;
  accountsValid: boolean = false;
  isLoading: boolean = false;
  accountsArray: any = [];
  subcription: any;
  showAddCampus: boolean = true;
  activeIndex: number = 0;
  selectedGroupAccountId: string = '';
  selectedGroupAccountDomain: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private http: HttpService,
  ) {
    this.columns = [
      {
        field: 'group_logo',
        header: 'Group Account Logo',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_full_name',
        header: 'Group Account Full Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_short_name',
        header: 'Group Account Short Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_title',
        header: 'Group Account Title Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_email',
        header: 'Group Account Email',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_first_name',
        header: 'First Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_last_name',
        header: 'Last Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_account_code',
        header: 'Group Account Code',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_domain_name',
        header: 'Group Account Domain Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_email_signature',
        header: 'Group Account Email Signature',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
    ];

    this.inputData = [
      {
        id: 1,
        label: 'Group Account Full Name',
        type: 'text',
        controlfield: 'group_full_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 2,
        label: 'Group Account Short Name',
        type: 'text',
        controlfield: 'group_short_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 3,
        label: 'Group Account Title Name',
        type: 'text',
        controlfield: 'group_title',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 4,
        label: 'Group Account Email',
        type: 'text',
        controlfield: 'group_email',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 5,
        label: 'Group Account Domain Name',
        type: 'text',
        controlfield: 'group_domain_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 6,
        label: 'Group Account Code',
        type: 'text',
        controlfield: 'group_account_code',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 7,
        label: 'Group Account First Name',
        type: 'text',
        controlfield: 'group_first_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 8,
        label: 'Group Account Last Name',
        type: 'text',
        controlfield: 'group_last_name',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 9,
        label: 'Group Account Email Signature',
        type: 'text',
        controlfield: 'group_email_signature',
        fieldtype: 'editor',
        mandatory: false,
        isDisable: false,
      },
    ];

    this.campusDetails = [
      {
        id: 2,
        label: 'Full Name',
        type: 'text',
        controlfield: 'full_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 3,
        label: 'Short Name',
        type: 'text',
        controlfield: 'short_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 4,
        label: 'Title Name',
        type: 'text',
        controlfield: 'title',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 5,
        label: 'Product',
        type: 'text',
        controlfield: 'product',
        fieldtype: 'dropdown',
        array: ['Neo Pat', 'Neo Hire'],
        mandatory: true,
        isDisable: false,
      },
      {
        id: 6,
        label: 'Primary Email',
        type: 'text',
        controlfield: 'primary_email',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 6,
        label: 'Secondary Email',
        type: 'text',
        controlfield: 'secondary_email',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 7,
        label: 'Pincode',
        type: 'text',
        controlfield: 'pincode',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 8,
        label: 'City',
        type: 'text',
        controlfield: 'city',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 9,
        label: 'District',
        type: 'text',
        controlfield: 'district',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 10,
        label: 'State',
        type: 'text',
        controlfield: 'state',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 11,
        label: 'Country',
        type: 'text',
        controlfield: 'country',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 12,
        label: 'Address',
        type: 'text',
        controlfield: 'address',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 13,
        label: 'Domain Name',
        type: 'text',
        controlfield: 'domain_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 13,
        label: 'Account Code',
        type: 'text',
        controlfield: 'account_code',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 14,
        label: 'Mobile Number',
        type: 'text',
        controlfield: 'mobile_no',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 15,
        label: 'Alternate Number',
        type: 'text',
        controlfield: 'alternate_no',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 16,
        label: 'User Role',
        type: 'text',
        controlfield: 'user_role',
        fieldtype: 'dropdown',
        array: ['Admin'],
        mandatory: true,
        isDisable: false,
      },
      {
        id: 17,
        label: 'First Name',
        type: 'text',
        controlfield: 'first_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 18,
        label: 'Last Name',
        type: 'text',
        controlfield: 'last_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 19,
        label: 'Email Signature',
        type: 'text',
        controlfield: 'email_signature',
        fieldtype: 'editor',
        mandatory: false,
        isDisable: false,
      },
    ];
    this.subcription = this.globalService.currentPatchValue.subscribe(
      (message: any) => {
        this.selectedGroupAccountId = Object.keys(message).length != 0 ? message.group_accounts.group_account_id : null;;
        this.selectedGroupAccountDomain = Object.keys(message).length != 0  ? message.group_accounts.group_domain_name : null;
        this.editInput = true;
        this.accountsData = [];
        this.accountsArray = [];
        this.showAddCampus = true;
        if (message.account) {
          _.forEach(this.campusDetails, (element: any) => {
            element.isDisable = true;
          });
          _.forEach(this.inputData, (element: any) => {
            element.isDisable = true;
          });
          this.createNewField = _.cloneDeep(message.account);
          _.forEach(message.accounts, (event: any) => {
            event.product = event.product === 1 ? 'Neo Pat' : 'Neo Hire';
            const campusFormGroup: any =
              this.createFormGroupFromCampusDetails();
            campusFormGroup.patchValue(event);
            this.accountsData.push(campusFormGroup);
            const newTabHeader = event.full_name;
            let newTabClosable: boolean = false;
            this.accountsArray.push({
              header: newTabHeader,
              closable: newTabClosable,
              isDisable: false,
            });
            _.forEach(this.accountsArray, (element: any) => {
              element.isDisable = true;
            });
          });
          return;
        }
        this.createNewField = _.cloneDeep(message);
      }
    );
  }

  async ngOnInit() {
    this.showAddCampus = false;
    this.editInput = false;
    this.createNewField = this.createFormGroupFromInputData();
    this.addCampus();
  }

  clearAll() {
    this.createNewField.reset();
    this.accountsData = [];
    this.selectedGroupAccountId = '';
    this.selectedGroupAccountDomain = '';
    this.accountsArray = [];
    this.editInput = false;
    this.createSidebarShow = true;
    const campusFormGroup = this.createFormGroupFromCampusDetails();
    this.accountsData.push(campusFormGroup);
    const newTabIndex = this.accountsData.length;
    const newTabHeader = `College`;
    let newTabClosable: boolean = true;
    if (this.accountsData.length === 1) {
      newTabClosable = false;
    }
    this.accountsArray.push({
      header: newTabHeader,
      closable: newTabClosable,
      isDisable: false,
    });
    this.clearDisable();
  }

  ngOnDestroy() {
    this.subcription.unsubscribe();
  }

  editAccount() {
    this.editInput = false;
    _.forEach(this.campusDetails, (element: any) => {
      if (
        element.controlfield !== 'primary_email' &&
        element.controlfield !== 'domain_name' &&
        element.controlfield !== 'product' &&
        element.controlfield !== 'account_code'
      ) {
        element.isDisable = false;
      }
    });
    _.forEach(this.inputData, (element: any) => {
      if (
        element.controlfield !== 'group_domain_name' &&
        element.controlfield !== 'group_email' &&
        element.controlfield !== 'group_account_code'
      ) {
        element.isDisable = false;
      }
    });
  }

  clearDisable(){
    this.editInput = false;
    _.forEach(this.campusDetails, (element: any) => {
        element.isDisable = false;
    });
    _.forEach(this.inputData, (element: any) => {
        element.isDisable = false;
    });
  }

  async addCampus() {
    this.createSidebarShow = true;
    const campusFormGroup = this.createFormGroupFromCampusDetails();
    this.accountsData.push(campusFormGroup);
    const newTabIndex = this.accountsData.length;
    const newTabHeader = `College`;
    let newTabClosable: boolean = true;
    this.showAddCampus = false;
    if (this.accountsData.length === 1) {
      newTabClosable = false;
    }
    _.forEach(this.campusDetails, (element: any) => {
        element.isDisable = true;
    });
    _.forEach(this.inputData, (element: any) => {
        element.isDisable = true;
    });
    this.accountsArray.push({
      header: newTabHeader,
      closable: newTabClosable,
      isDisable: false,
    });
  }

  createFormGroupFromInputData() {
    this.accountsData = [];
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  trackByFn(index: Number, item: any) {
    return index; // Use a unique identifier from your data (e.g., 'id')
  }

  handleChange(e: any) {
    this.accountsData.splice(e.index, 1);
    this.accountsArray.splice(e.index, 1)
    this.showAddCampus = true;
    if (this.accountsData.length > 0) {
      this.activeIndex = 0
    }
  }

  createFormGroupFromCampusDetails() {
    const formGroupConfig: any = {};
    this.campusDetails.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  async onSubmitCreate() {
    this.formValid = this.createNewField.valid;
    const payload: any = {
      group_accounts: {},
      accounts: {},
    };

    if (!this.formValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields',
      });
      return;
    }

    const obj: any = await this.assignAndValidation(
      this.createNewField.controls
    );
    if (obj === undefined) {
      return;
    }
    payload.group_accounts.full_name = obj.group_full_name;
    payload.group_accounts.short_name = obj.group_short_name;
    payload.group_accounts.title = obj.group_title;
    payload.group_accounts.email = obj.group_email;
    payload.group_accounts.first_name = obj.group_first_name;
    payload.group_accounts.last_name = obj.group_last_name;
    payload.group_accounts.domain_name = obj.group_domain_name;
    payload.group_accounts.email_signature = obj.email_signature;
    payload.group_accounts.group_account_code = obj.group_account_code;

    this.accountsValid = this.accountsData[0].valid;
    if (!this.accountsValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields in accounts section',
      });
      return;
    } else {
      const accountsObj = await this.assignAndValidation(this.accountsData[0].controls);
      if (accountsObj === undefined) {
        return;
      }
      payload.accounts = accountsObj;
    }

    this.isLoading = true;
    const invite = await this.groupAccountAndAccountsCreation(payload);
    if (invite && invite.success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Accounts updated successfully',
      });
      this.sideBarClose();
      this.clearAll();
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: invite.message,
      });
    }
    this.isLoading = false;
    return;
  }

  async createCampus() {
    const payload: any = {
      accounts: {},
    };
    payload.group_account_id = this.selectedGroupAccountId
    payload.group_accounts_domain = this.selectedGroupAccountDomain
    const lastIndex = this.accountsData.length - 1;
    const lastItem = this.accountsData[lastIndex];

    this.accountsValid = lastItem.valid;
    if (!this.accountsValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields in accounts section',
      });
      return;
    } else {
      const accountsObj = await this.assignAndValidation(lastItem.controls);
      if (accountsObj === undefined) {
        return;
      }
      payload.accounts = accountsObj;
    }

    this.isLoading = true;
    const invite = await this.createAccounts(payload);
    if (invite && invite.success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Accounts added successfully',
      });
      this.sideBarClose();
      this.clearAll();
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: invite.message,
      });
    }
    this.isLoading = false;
    // for (const element of this.accountsData) {
    //   this.accountsValid = element.valid;
    //   if (!this.accountsValid) {
    //     this.messageService.add({
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: 'Please fill all the mandatory Fields in accounts section',
    //     });
    //     return;
    //   } else {
    //     const accountsObj = await this.assignAndValidation(element.controls);
    //     if (accountsObj === undefined) {
    //       return;
    //     }
    //     payload.accounts.push(accountsObj);
    //   }
    // }
    return;
  }

  async assignAndValidation(payload?: any) {
    let obj: any = {};
    for (const controlField in payload) {
      if (controlField === 'group_email') {
        const emailValue = payload[controlField].value;
        if (!this.emailPattern.test(emailValue)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Group admin email pattern is not valid',
          });
          return;
        }
      }
      if (controlField === 'pincode') {
        const value = payload[controlField].value;
        if (!this.numericPattern.test(value)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `${controlField} should contain only numbers`,
          });
          return;
        }
      }
      if (controlField === 'primary_email') {
        const emailValue = payload[controlField].value;
        if (!this.emailPattern.test(emailValue)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Accounts primary email pattern is not valid',
          });
          return;
        }
      }
      if (controlField === 'secondary_email') {
        const emailValue = payload[controlField].value;
        if (!this.emailPattern.test(emailValue)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Accounts secondary email pattern is not valid',
          });
          return;
        }
      }
      if (controlField === 'product') {
        payload[controlField].value =
          payload[controlField].value === 'Neo Pat' ? 1 : 2;
      }
      if (controlField === 'mobile_no' || controlField === 'alternate_no') {
        const number = payload[controlField].value;
        if (!this.phoneNumber.test(number)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Accounts Mobile Number and Alternate Number should contain number only',
          });
          return;
        }
      }
      obj[controlField] = payload[controlField].value;
    }
    return obj;
  }

  async groupAccountAndAccountsCreation(body: any) {
    const fetchStudentsDetails = await this.http.post(
      '/accounts/create',
      body
    );
    return fetchStudentsDetails;
  }

  async groupAccountAndAccountsUpdation(body: any) {
    const fetchStudentsDetails = await this.http.post(
      '/accounts/update',
      body
    );
    return fetchStudentsDetails;
  }

  async createAccounts(body: any) {
    const fetchStudentsDetails = await this.http.post(
      '/accounts/addAccounts',
      body
    );
    return fetchStudentsDetails;
  }

  async sideBarClose() {
    this.createSidebarShow = false;
    this.isLoading = false;
    this.closeSideBar.emit(this.createSidebarShow);
  }

  async onEditFields() {
    this.formValid = this.createNewField.valid;
    const payload: any = {
      group_accounts: {},
      accounts: [],
    };

    if (!this.formValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields',
      });
      return;
    }

    const obj: any = await this.assignAndValidation(
      this.createNewField.controls
    );
    if (obj === undefined) {
      return;
    }
    payload.group_accounts.full_name = obj.group_full_name;
    payload.group_accounts.short_name = obj.group_short_name;
    payload.group_accounts.title = obj.group_title;
    payload.group_accounts.email = obj.group_email;
    payload.group_accounts.first_name = obj.group_first_name;
    payload.group_accounts.last_name = obj.group_last_name;
    payload.group_accounts.domain_name = obj.group_domain_name;
    payload.group_accounts.email_signature = obj.email_signature;
    payload.group_accounts.group_account_code = obj.group_account_code;

    for (const element of this.accountsData) {
      this.accountsValid = element.valid;
      if (!this.accountsValid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill all the mandatory Fields in accounts section',
        });
        return;
      } else {
        const accountsObj = await this.assignAndValidation(element.controls);
        if (accountsObj === undefined) {
          return;
        }
        payload.accounts.push(accountsObj);
      }
    }
    this.isLoading = true;
    const invite = await this.groupAccountAndAccountsUpdation(payload);
    if (invite && invite.success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Accounts updated successfully',
      });
      this.sideBarClose();
      this.clearAll();
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: invite.message,
      });
    }
    this.isLoading = false;
    return;
  }
}
