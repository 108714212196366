import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ApiResponse } from '../interfaces/api-response';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private api_url = environment.HOST_LINK;
  constructor(private httpClient: HttpClient) {
  }

  headerOptions(): HttpHeaders{
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: this.getAuthToken()
    })
    console.log(headers)
    return headers
  }

  private getAuthToken(): string {
    let token: any = localStorage.getItem('token');
    token = token ? token : ''
    return token;
  }

  get(endpoint: string): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .get<ApiResponse>(this.api_url + endpoint, { headers: this.headerOptions() })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  post(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .post<ApiResponse>(this.api_url + endpoint, body, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  put(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .put<ApiResponse>(this.api_url + endpoint, body, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  patch(endpoint: string, body: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .patch<ApiResponse>(this.api_url + endpoint, body, {
          headers: this.headerOptions(),
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  delete(endpoint: string, body?: any): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.httpClient
        .delete<ApiResponse>(this.api_url + endpoint, {
          headers: this.headerOptions(),
          body: body,
        })
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error: HttpErrorResponse) => {
            resolve(error.error);
          },
        });
    });
  }

  async uploadUsingSignedUrl(endpoint: any, payload: any): Promise<any> {
    return new Promise(async (resolved: any, rejected: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': payload.type,
        }),
        observe: 'response' as 'body',
      };
      this.httpClient
        .put(endpoint, payload, httpOptions)
        .subscribe((res: any) => {
          resolved(res);
        });
    });
  }
}
