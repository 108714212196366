import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './services/login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: string = 'SUPER ADMIN';
  path: string = ''
  constructor(
    private location: Location,
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path = this.location.path()
      }
    })

    this.loginService.validToken.subscribe((s: any) => {
      this.messageService.add({
        severity: 'error' , summary: 'Error' , detail: s.message
      })
    })
  }
}
