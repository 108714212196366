import { AppModule } from './../app.module';
import { Component, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-field-creation-table',
  templateUrl: './field-creation-table.component.html',
  styleUrls: ['./field-creation-table.component.css'],
  providers: [MessageService],
})
export class FieldCreationTableComponent {
  @ViewChild('overlayPanel') overlaypanel: any;
  searchValue: any = '';
  columns: any[] = [];
  dataList: any[] = [];
  searchColor: boolean = true;
  createSidebarShow: boolean = false;
  pageSize: number = 10;
  pageLimits: any[] = [];
  showFilter: boolean = false;
  showList: boolean = false;
  showDownload: boolean = false;
  inputData: any[] = [];
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  createNewField: FormGroup = new FormGroup({});
  formValid: boolean = false;
  indexnumber: number = 1;
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  actionOptions: any[] = [];
  selectedAction: any;
  openEditSidebar: boolean = false;
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  sideBarOperation = '';
  sideBarButton = '';
  // field_creation = new FormGroup({
  //   name: new FormControl('', {
  //     validators: Validators.required,
  //   }),
  //   FieldTypes: new FormControl<string[] | []>([]),
  // });

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private httpService: HttpService
  ) {
    this.pageLimits = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];
    this.columns = [
      {
        field: 'name',
        header: 'Field Name',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'FieldTypes',
        header: 'Field Types',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
    ];

    // this.dataList = [
    //   { field_name: '', field_type: 'Input Box' },
    //   { field_name: 'Last Name', field_type: 'Input Box' },
    // ];

    this.inputData = [
      {
        id: 1,
        label: 'Field Name',
        type: 'text',
        controlfield: 'name',
        fieldtype: 'input',
        mandatory: true,
      },
      {
        id: 2,
        label: 'Field Type',
        type: 'text',
        controlfield: 'FieldTypes',
        fieldtype: 'dropdown',
        array: ['Basic'],
        mandatory: true,
      },
    ];

    this.actionOptions = [
      { label: 'Edit', value: 'edit' },
      { label: 'Delete', value: 'delete' },
    ];
    this.searchValue = '';
  }

  ngOnInit() {
    this.createNewField = this.createFormGroupFromInputData();
    this.httpService.get('/fields').then((data: any) => {
      this.dataList = data.data;
    });
  }

  // async createField() {
  //   const field_name = this.field_creation.controls['name'].value;
  //   const field_types = this.field_creation.controls['FieldTypes'].value;
  //   const payload = {
  //     field_name: field_name,
  //     field_types: field_types,
  //   };
  //   const data = await this.httpService.post('/fields', payload);
  //   console.log('This is the data response', data);
  // }

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  bulkUpload() {
    this.showBulkUpload = true;
  }

  createNew() {
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
    console.log(this.dataList);
    this.sideBarOperation = 'Create Fields';
    this.sideBarButton = 'create';
  }

  onSubmitCreate() {
    this.formValid = this.createNewField.valid;
    // console.log(this.field_creation.value);
    console.log('Craetion');
    console.log(this.createNewField.value);
    const payload = {
      field_name: this.createNewField.get('name')?.value,
      field_types: this.createNewField.get('FieldTypes')?.value,
    };
    console.log(payload);
    this.httpService.post('/fields', payload).then((data) => {
      if (data.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Field Creation',
          detail: 'Field successfully created!',
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Field Creation',
          detail: data.message,
        });
      }
      console.log(data);
    });
  }

  openEditFiled(event: any) {
    this.sideBarOperation = 'Update Field';
    this.sideBarButton = 'Update';
    this.selectedOptions = _.cloneDeep(event);
    console.log(this.selectedOptions);
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 2) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Select only one row',
      });
      return;
    }
    this.selectedOptions[0]!.FieldTypes =
      this.selectedOptions[0].FieldTypes.map((ele: any) => {
        return ele.field_type;
      });
    this.createNewField.patchValue(this.selectedOptions[0]);
    this.patchedValues = _.cloneDeep(this.createNewField);
    this.createSidebarShow = true;
  }

  onEditFields() {
    let changedFields: any = {};
    console.log('this.createNewField', this.createNewField);
    for (const controlField in this.createNewField.controls) {
      if (
        this.patchedValues.controls[controlField].value !==
        this.createNewField.controls[controlField].value
      ) {
        changedFields[controlField] =
          this.patchedValues.controls[controlField].value;
      }
    }
    if (Object.keys(changedFields).length <= 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Fields are Unchanged',
      });
      this.createSidebarShow = !this.createSidebarShow;
      return;
    }
    this.createSidebarShow = !this.createSidebarShow;

    // this.createNewField = this.createFormGroupFromInputData();
    // this.patchedValues = _.cloneDeep(this.createNewField);

    const payload = {
      field_id: this.selectedOptions[0].field_id,
      field_name: this.patchedValues.value.name,
      field_types: this.patchedValues.value.FieldTypes,
    };
    console.log('Patched valued', this.patchedValues.value);
    console.log('This is the update', payload);

    this.httpService.put('/fields', payload).then((data) => {
      if (data.success) {
        this.messageService.add({
          severity: 'success',
          summary: 'Field Updation',
          detail: 'Successfully Updated',
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Field Updation',
          detail: 'Field is not updated',
        });
      }
    });
  }
  deleteData(event: any) {
    const selectedOption = event[0];
    console.log('Delete field id', selectedOption.field_id);
    this.httpService
      .delete(`/fields/${selectedOption.field_id}`)
      .then((data) => {
        if (data.success) {
          this.messageService.add({
            severity: 'success',
            summary: 'Field Deletion',
            detail: 'Successfully Deleted',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Field Deletion',
            detail: 'Field is not deleted!',
          });
        }
      });
  }

  showHistory() {}

  saveBulkUpload() {}
}
