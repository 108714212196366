<div *ngIf="isLoading" class="{{ isLoading ? 'loadingOverlay' : '' }}">
  <p-progressSpinner
    [style]="{ height: '30px', width: '30px' }"
    strokeWidth="7"
    animationDuration="0.5s"
  >
  </p-progressSpinner>
</div>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<div class="programme-offered-cont">
  <div class="button-cont">
    <button
      pButton
      pRipple
      class="p-button-create blue-filled-btn"
      (click)="createNew()"
      label="Create new"
    ></button>
    <p-sidebar [(visible)]="showBulkUpload" position="right">
      <ng-template pTemplate="header">
        <!-- <div class="header-cont">
          <span>Bulk Upload</span>
          <button
            pbutton
            class="p-button-create blue-filled-btn"
            (click)="saveBulkUpload()"
            type="submit"
          >
            Save & Next
          </button>
        </div> -->
        <p-divider layout="horizontal"></p-divider>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="bulkUpload-cnt">
          <div class="download-excel">
            <span class="download-header">Dowload Bulk Upload Sample</span>
            <div class="download-body">
              <div class="csv-icon">
                <div class="icon">
                  <img src="../assets/programme-offered/csv.svg" alt="" />
                </div>
                <span>Template_Data.csv</span>
              </div>
              <div class="download-icon"><i class="pi pi-download"></i></div>
            </div>
          </div>
          <div class="upload-excel">
            <span class="download-header">Upload Bulk Mapping File </span>
            <div class="upload-body">
              <div class="download-icon"><i class="pi pi-upload"></i></div>
              <div class="upload-texts">
                <span class="bold-text"
                  >Drag & Drop Your files or Choose file</span
                >
                <span class="normal-text">.XLS,.XLSX,.CSV Formate only</span>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="createSidebarShow" position="right">
      <div class="header-cont">
        <span>{{ sideBarOperation }}</span>
        <button
          (click)="
            sideBarButton === 'create' ? onSubmitCreate() : onEditFields()
          "
          pbutton
          class="p-button-create blue-filled-btn"
          type="submit"
        >
          {{ sideBarButton }}
        </button>
      </div>
      <p-divider layout="horizontal"></p-divider>
      <form action="" [formGroup]="attribute_creation" class="createform">
        <span class="p-float-label">
          <input
            type="text"
            class="fields"
            pInputText
            formControlName="attribute_name"
            [maxLength]="30"
          />
          <label for="attribute_name">Attribute Name*</label>
        </span>

        <span class="p-float-label">
          <textarea
            class="text-area"
            id="attribute_description"
            pInputTextarea
            formControlName="attribute_description"
            [maxLength]="50"
            [autoResize]="false"
          ></textarea>
          <label for="attribute_description">Attribute Description</label>
        </span>

        <span class="p-float-label">
          <p-dropdown
            styleClass="fields"
            [filter]="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="38"
            [options]="fields"
            optionLabel="name"
            optionValue="name"
            formControlName="field"
            placeholder="Select Field"
            [showClear]="true"
            #selectedField
          >
          </p-dropdown>
          <label for="">Field*</label>
        </span>
        <span class="p-float-label">
          <input pInputText formControlName="placeholder" class="fields" />
          <label for="placeholder">Placeholder</label>
        </span>
        <div
          *ngIf="
            selectedField.value === 'Input' ||
            selectedField.value === 'Text' ||
            selectedField.value === 'Editor' ||
            selectedField.value === 'Textarea'
              ? true
              : false
          "
          class="flex align-items-center gap-1"
        >
          <!-- <p-checkbox
            [value]="true"
            [trueValue]="true"
            inputId="special"
            formControlName="isSpecialCharacter"
          ></p-checkbox>
          <label for="special">Special Characters Allowance</label> -->
        </div>
        <!-- <div
          *ngIf="
            selectedField.value == 'Date Picker - Single' ||
            selectedField.value == 'Date Picker - Multiple' ||
            selectedField.value == 'Date Picker - Ranged' ||
            selectedField.value == 'Month Picker' ||
            selectedField.value == 'Year Picker'
          " 
        >
          <span class="p-float-label mb-1">
            <p-calendar
              styleClass="fields"
              formControlName="min_date"
              [showButtonBar]="true"
              dateFormat="dd/mm/yy"
            ></p-calendar>
            <label for="min_date">Minimum Date</label>
          </span>
          <span class="p-float-label mb-1">
            <p-calendar
              styleClass="fields"
              formControlName="max_date"
              [showButtonBar]="true"
              dateFormat="dd/mm/yy"
            ></p-calendar>
            <label for="min_date">Maximum Date</label>
          </span>
        </div> -->
        <span
          class="p-float-label"
          *ngIf="selectedField.value === 'File Upload'"
        >
          <p-multiSelect
            styleClass="fields"
            [options]="fileTypesArray"
            optionLabel="option"
            optionValue="value"
            formControlName="file_types"
            display="chip"
          ></p-multiSelect>
          <label for="file_types">File Types*</label>
        </span>
        <!-- <span
          class="p-float-label"
          *ngIf="selectedField.value === 'File Upload'"
        >
          <p-inputNumber
            styleClass="fields"
            formControlName="max_file_size"
            placeholder="Enter file size in MB"
          >
          </p-inputNumber>
          <label for="file_size">Maximum File Size*</label>
        </span> -->
        <span
          class="p-float-label"
          *ngIf="
            selectedField.value === 'Dropdown - Single' ||
            selectedField.value === 'Dropdown - Multiple'
          "
        >
          <p-chips
            separator=","
            formControlName="field_options"
            styleClass="fields"
            placeholder="Enter dropdown options"
          ></p-chips>
          <label for="field_name">Dropdown options*</label>
        </span>
        <span class="p-float-label">
          <input pInputText formControlName="sample_data" class="fields" />
          <label for="placeholder">Sample Data</label>
        </span>
        <!-- <span class="p-float-label" *ngIf="selectedField.value === 'Currency'">
          <p-dropdown
            styleClass="fields"
            [options]="currencyType"
            optionLabel="option"
            optionValue="value"
            formControlName="currency_type"
            [filter]="true"
            placeholder="Choose currency type"
            [showClear]="true"
            (onChange)="selectedCurrencyType($event)"
            [virtualScroll]="true"
            [virtualScrollItemSize]="38"
          ></p-dropdown>

          <label for="currency_type">Currency*</label>
        </span> -->

        <!-- <div
          *ngIf="
            selectedField.value === 'Text' ||
            selectedField.value === 'Password' ||
            selectedField.value === 'Textarea' ||
            selectedField.value === 'Number' ||
            selectedField.value === 'Editor'
          "
        >
          <div class="multi-field">
            <span class="p-float-label">
              <p-inputNumber
                styleClass="fields"
                [showClear]="true"
                [useGrouping]="false"
                formControlName="min_length"
                [min]="0"
              >
              </p-inputNumber>
              <label for="min_length">Minimum Length</label>
            </span>
            <span class="p-float-label">
              <p-inputNumber
                styleClass="fields"
                [showClear]="true"
                [useGrouping]="false"
                formControlName="max_length"
                [min]="0"
              >
              </p-inputNumber>
              <label for="max_length">Maximum Length</label>
            </span>
          </div>
        </div> -->

        <!-- <div *ngIf="selectedField.value === 'Mobile Number'">
          <span class="p-float-label mb-1">
            <p-dropdown
              styleClass="fields"
              [options]="mobileNoCodes"
              formControlName="mobile_no"
              placeholder="placeholder"
              optionLabel="option"
              optionValue="value"
              [showClear]="true"
              [filter]="true"
              [virtualScroll]="true"
              [virtualScrollItemSize]="38"

            ></p-dropdown>
            <label for="min_number">Country*</label>
          </span>
        </div> -->

        <!-- <div>
          <div *ngIf="selectedField.value === 'Number'">
            <div class="multi-field">
              <span class="p-float-label mb-1">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="min_number"
                  [mode]="'decimal'"
                >
                </p-inputNumber>
                <label for="min_number">Minimum Number</label>
              </span>
              <span class="p-float-label mb-1">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="max_number"
                  [mode]="'decimal'"
                >
                </p-inputNumber>
                <label for="max_number">Maximum Number</label>
              </span>
            </div>
          </div>

          <div *ngIf="selectedField.value === 'Number'">
            <span class="p-float-label">
              <p-inputNumber
                styleClass="fields"
                [showClear]="true"
                [useGrouping]="false"
                formControlName="max_fraction"
                [mode]="'decimal'"
              >
              </p-inputNumber>
              <label for="max_number">Maximum Fraction</label>
            </span>
          </div>
          <div *ngIf="selectedField.value === 'Currency' && currency">
            <div class="flex flex-row gap-6 multi-field">
              <span class="p-float-label">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="min_number"
                  mode="currency"
                  [currency]="currency"
                >
                </p-inputNumber>
                <label for="min_number">{{ minLabel }}</label>
              </span>
              <span class="p-float-label">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="max_number"
                  mode="currency"
                  [currency]="currency"
                >
                </p-inputNumber>
                <label for="max_number">{{ maxLabel }}</label>
              </span>
            </div>
          </div>
          <div *ngIf="selectedField.value === 'Percentage'">
            <div class="flex flex-row gap-6 multi-field">
              <span class="p-float-label">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="min_number"
                  [min]="0"
                  prefix="% "
                >
                </p-inputNumber>
                <label for="min_number">Minimum Percentage</label>
              </span>
              <span class="p-float-label">
                <p-inputNumber
                  styleClass="fields"
                  [showClear]="true"
                  [useGrouping]="false"
                  formControlName="max_number"
                  [max]="100"
                  prefix="% "
                >
                </p-inputNumber>
                <label for="max_number">Maximum Percentage</label>
              </span>
            </div>
          </div>
        </div> -->
      </form>
    </p-sidebar>
  </div>
  <div class="table-cont table-relative">
    <app-table [columns]="columns" [dataList]="dataList" [dataCount]="dataCount" [pageLimits]="pageLimits"
      [actionOptions]="actionOptions" [totalPage]="totalPage" [action]="true" [checkBox]="true"
      [allRowsSelected]="checkedAll" (openEdit)="openEditFiled($event)" (search)="searchValueChange($event)"
      (rowLength)="rowLengthChange($event)" (pageNo)="pageChange($event)" [pageAndRow]="pageAndRow"
      ></app-table>
    <p-toast></p-toast>
  </div>
</div>
