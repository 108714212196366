import { CollegeProfileComponent } from './college-profile/college-profile.component';
import { CampusLoginTemplateComponent } from './campus-login/campus-login.component';
import { DashboardComponent } from './dashboard/dashboard.component'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './routes/auth.guard';
import { ProgrammeOfferedComponent } from './programme-offered/programme-offered.component';
import { FieldCreationComponent } from './field-creation/field-creation.component';
import { GlobalDataComponent } from './global-data/global-data.component';
import { GroupCreationComponent } from './group-creation/group-creation.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: CampusLoginTemplateComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb:
        [{
          label: 'DashBoard',
          url: ''
        }]
    }
  },
  {
    path: 'field-creation',
    component: FieldCreationComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb:
        [{
          label: 'Field Creation',
          url: ''
        }]
    }
  },
  {
    path: 'global-data',
    component: GlobalDataComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb:
        [{
          label: 'Global Data',
          url: ''
        }]
    }
  },
  {
    path: 'accounts',
    component: CollegeProfileComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb:
        [{
          label: 'Accounts',
          url: ''
        }]
    }
  },
  {
    path: 'groupCreation',
    component: GroupCreationComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: [{ label: 'Account Creation', url: '' }]
    }
  },
  {
    path: 'groupCreation/create-account',
    loadChildren: () => import('./create-account/create-account.module').then(m => m.CreateAccountModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: [
      {label: 'Accounts', url: '/groupCreation'},
      {label: 'Create Account', url: ''}
    ] }
  },
  {
    path: 'groupCreation/:id',
    loadChildren: () => import('./create-account/create-account.module').then(m => m.CreateAccountModule),
    canActivate: [AuthGuard],
    data: { breadcrumb: [
      {label: 'Accounts', url: '/groupCreation'},
      {label: 'Account Name', url: ''}
    ] }

  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
