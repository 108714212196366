import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalServicesService } from '../services/global-service.service';
import { FileUploadService } from 'src/app/services/fileupload.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { SuperAdminService } from '../services/super-admin.service';
import { MessageService } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { AccountCreateComponent } from '../account-create/account-create.component';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-college-profile',
  templateUrl: './college-profile.component.html',
  styleUrls: ['./college-profile.component.css'],
})
export class CollegeProfileComponent {
  @ViewChild('accountCreate', { static: false })
  accountCreateComponent!: AccountCreateComponent;
  columns: any[] = [];
  dataList: any[] = [];
  accountsData: any[] = [];
  pageLimits: any[] = [];
  actionOptions: any[] = [];
  searchValue: any = '';
  filterResponse: any;
  pageAndRow: boolean = false
  inputData: any[] = [];
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  bucketData: any = {};
  patchedValues: any = {};
  rowsize: number = 10;
  dataCount: number = 0;
  totalPage: number = 0;
  showAction: boolean = false;
  selectedFilters: any = '';
  filterData: { [key: string]: any[] };
  s3Domain: string = 'https://s3.amazonaws.com';
  createNewField: FormGroup = new FormGroup({});
  uploadingFile: boolean = false;
  createSidebarShow: boolean = false;
  formValid: boolean = false;
  fieldSelectedForDownload: any[] = [];
  downloadColumnAs: string = 'excel';
  schoolDetails: any;
  isLoading: boolean = false;
  selectedPage: any = 1;
  downloadFile: any;

  constructor(
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private superAdminService: SuperAdminService,
    private fileUploadService: FileUploadService,
    private http: HttpService,
  ) {
    this.pageLimits = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];

    this.columns = [
      {
        field: 'redirect',
        header: 'Redirect',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_logo',
        header: 'Group Account Logo',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_full_name',
        header: 'Group Account Full Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_short_name',
        header: 'Group Account Short Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_title',
        header: 'Group Account Title Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_email',
        header: 'Group Account Email',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_first_name',
        header: 'First Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_last_name',
        header: 'Last Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_domain_name',
        header: 'Group Account Domain Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'group_account_code',
        header: 'Group Account Code',
        sortable: true,
        freezable: true,
        tableDefault: true,
      },
      {
        field: 'group_email_signature',
        header: 'Group Account Email Signature',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
    ];

    this.inputData = [
      {
        id: 1,
        label: 'Group Account Full Name',
        type: 'text',
        controlfield: 'group_full_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 2,
        label: 'Group Account Short Name',
        type: 'text',
        controlfield: 'group_short_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 3,
        label: 'Group Account Title Name',
        type: 'text',
        controlfield: 'group_title',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 4,
        label: 'Group Account Email',
        type: 'text',
        controlfield: 'group_email',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 5,
        label: 'Group Account Domain Name',
        type: 'text',
        controlfield: 'group_domain_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 6,
        label: 'Group Account Code',
        type: 'text',
        controlfield: 'group_account_code',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 7,
        label: 'Group Account First Name',
        type: 'text',
        controlfield: 'group_first_name',
        fieldtype: 'input',
        mandatory: true,
        isDisable: false,
      },
      {
        id: 8,
        label: 'Group Account Last Name',
        type: 'text',
        controlfield: 'group_last_name',
        fieldtype: 'input',
        mandatory: false,
        isDisable: false,
      },
      {
        id: 9,
        label: 'Group Account Email Signature',
        type: 'text',
        controlfield: 'group_email_signature',
        fieldtype: 'editor',
        mandatory: false,
        isDisable: false,
      },
    ];
    this.actionOptions = [
      { label: 'Edit', value: 'edit' },
      { label: 'Delete', value: 'delete' },
      { label: 'History', value: 'history' },
    ];
    this.searchValue = '';
    this.filterData = {
      'Company Name': [
        { name: 'Google', value: '1' },
        { name: 'Lovely Professional University', value: '2' },
      ],
      Email: [],
      'Domain Name': [],
    };
  }

  onSidebarHide() {
    if (this.accountCreateComponent) {
      this.accountCreateComponent.clearAll();
    }
  }

  async ngOnInit() {
    this.createNewField = this.createFormGroupFromInputData();
    const body = { limit: this.rowsize, page: this.selectedPage };
    this.isLoading = true;
    const response: any = await this.fetchGroupAccountsAndAccounts(body);
    if (response.success) {
      this.accountsData = await response.data.result;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    const newArray: any[] = [];
    this.accountsData.forEach((element) => {
      newArray.push(element.groupAccount);
    });

    const transformedData: any = {};
    for (const item of this.accountsData) {
      const schoolId = item.groupAccount.group_account_id;
      let accountsArray: any[];

      // Use map to initialize accountsArray
      accountsArray = item.accounts.map((accounts: any) => {
        const { account_id, ...accountsData } = accounts;
        return accountsData;
      });
      transformedData[schoolId] = accountsArray;
    }
    for (const item of newArray) {
      const schoolId = item.group_account_id;
      if (transformedData[schoolId]) {
        this.dataList.push({ ...item, accounts: transformedData[schoolId] });
      }
    }
    this.isLoading = false;
  }

  editorContent: FormControl = new FormControl();

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  async sideBarClose(event: any) {
    this.accountsData = [];
    this.dataList = [];
    this.createSidebarShow = event;
    const body = { };
    const response: any = await this.fetchGroupAccountsAndAccounts(body);
    if (response.success) {
      this.accountsData = await response.data.result;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    const newArray: any[] = [];
    this.accountsData.forEach((element) => {
      newArray.push(element.groupAccount);
    });

    const transformedData: any = {};
    for (const item of this.accountsData) {
      const schoolId = item.groupAccount.group_account_id;
      let accountsArray: any[];

      // Use map to initialize accountsArray
      accountsArray = item.accounts.map((accounts: any) => {
        const { account_id, ...accountsData } = accounts;
        return accountsData;
      });

      transformedData[schoolId] = accountsArray;
    }

    for (const item of newArray) {
      const schoolId = item.group_account_id;

      if (transformedData[schoolId]) {
        this.dataList.push({ ...item, accounts: transformedData[schoolId] });
      }
    }
  }

  createNew() {
    this.createNewField.reset();
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
  }

  onSubmitCreate() {
    this.formValid = this.createNewField.valid;
  }

  async fetchGroupAccountsAndAccounts(body: any) {
    const fetchGroupAccountsAndAccounts =
      await this.http.post(
        '/accounts/fetch',
        body
      );
    return fetchGroupAccountsAndAccounts;
  }

  openEditFiled(event: any) {
    this.selectedOptions = _.cloneDeep(event);
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 1) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Select only one row',
      });
      return;
    }
    this.createNewField.patchValue(this.selectedOptions[0]);
    this.patchedValues = _.cloneDeep(this.createNewField);
    this.globalService.changePatchValue(this.patchedValues);
    this.createSidebarShow = true;
  }

  onEditFields() {
    let changedFields: any = {};
    for (const controlField in this.createNewField.controls) {
      if (
        this.patchedValues.controls[controlField].value !==
        this.createNewField.controls[controlField].value
      ) {
        changedFields[controlField] =
          this.patchedValues.controls[controlField].value;
      }
    }
    if (Object.keys(changedFields).length <= 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Fields are Unchanged',
      });
      this.createSidebarShow = !this.createSidebarShow;
      return;
    }
    this.createSidebarShow = !this.createSidebarShow;
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully Updated',
    });
    this.createNewField = this.createFormGroupFromInputData();
    this.patchedValues = _.cloneDeep(this.createNewField);
  }

  async fetchStudentsDetails(body: any) {
    const staffDetails = await this.http.post(
      '/api/coe/fetch-students-details',
      body
    );
    return staffDetails;
  }

  async rowLengthChange(length: any) {
    this.accountsData = [];
    this.dataList = [];
    this.rowsize = length;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response: any = await this.fetchGroupAccountsAndAccounts(body);
    if (response.success) {
      this.dataList = response.data.result;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    const newArray: any[] = [];
    this.accountsData.forEach((element) => {
      newArray.push(element.groupAccount);
    });
    const transformedData: any = {};
    for (const item of this.accountsData) {
      const schoolId = item.groupAccount.group_account_id;
      let accountsArray: any[];
      accountsArray = item.accounts.map((accounts: any) => {
        const { account_id, ...accountsData } = accounts;
        return accountsData;
      });

      transformedData[schoolId] = accountsArray;
    }
    for (const item of newArray) {
      const schoolId = item.group_account_id;
      if (transformedData[schoolId]) {
        this.dataList.push({ ...item, accounts: transformedData[schoolId] });
      }
    }
    this.isLoading = false;
    return
  }

  async pageChange(selectedPage: any) {
    this.accountsData = [];
    this.dataList = [];
    this.selectedPage = selectedPage;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response: any = await this.fetchGroupAccountsAndAccounts(body);
    if (response.success) {
      this.dataList = response.data.result;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    const newArray: any[] = [];
    this.accountsData.forEach((element) => {
      newArray.push(element.groupAccount);
    });
    const transformedData: any = {};
    for (const item of this.accountsData) {
      const schoolId = item.groupAccount.group_account_id;
      let accountsArray: any[];

      // Use map to initialize accountsArray
      accountsArray = item.accounts.map((accounts: any) => {
        const { account_id, ...accountsData } = accounts;
        return accountsData;
      });

      transformedData[schoolId] = accountsArray;
    }

    for (const item of newArray) {
      const schoolId = item.group_account_id;

      if (transformedData[schoolId]) {
        this.dataList.push({ ...item, accounts: transformedData[schoolId] });
      }
    }
    this.isLoading = false;
    return
  }

  async searchValueChange(searchValue: any) {
    this.accountsData = [];
    this.dataList = [];
    this.searchValue = searchValue;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response: any = await this.fetchGroupAccountsAndAccounts(body);
    if (response.success) {
      this.accountsData = await response.data.result;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    const newArray: any[] = [];
    this.accountsData.forEach((element) => {
      newArray.push(element.groupAccount);
    });

    const transformedData: any = {};
    for (const item of this.accountsData) {
      const schoolId = item.groupAccount.group_account_id;
      let accountsArray: any[];

      // Use map to initialize accountsArray
      accountsArray = item.accounts.map((accounts: any) => {
        const { account_id, ...accountsData } = accounts;
        return accountsData;
      });

      transformedData[schoolId] = accountsArray;
    }
    for (const item of newArray) {
      const schoolId = item.group_account_id;
      if (transformedData[schoolId]) {
        this.dataList.push({ ...item, accounts: transformedData[schoolId] });
      }
    }
    this.isLoading = false;
    return
  }

  openAccountDetails(event: any) {
    this.createNewField.patchValue(event[0]);
    this.createNewField.patchValue({
      group_account_id: event[0].group_account_id
    });    

    this.globalService.changePatchValue({
      account: this.createNewField,
      accounts: event[0].accounts,
      group_accounts: event[0]
    });
    this.createSidebarShow = true;
  }
}
