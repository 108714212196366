import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from '../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    api_url = environment.HOST_LINK;
    token: any = localStorage.getItem('token')
    validToken: BehaviorSubject<any> = new BehaviorSubject({})

    constructor(private router: Router, private http: HttpClient) { }

    tokenValidation(mes: any) {
        this.validToken.next(mes)
    }

    options() {
        const headers = new HttpHeaders({
            Authorization: localStorage.getItem('token') ?? '',
        });
        return { headers };
    }

    removekeys() {
        setTimeout(() => {
            this.router.navigateByUrl('/login')
        }, 0);
        for (let item in localStorage) {
            //Need to add condition If need not to delete every localstorage keys
            localStorage.removeItem(item)
        }
    }

    isTokenValid(): Observable<any> {
        let data: any = this.http.get(`${this.api_url}/tokenvalidation`, this.options())
        return data
    }

}