<div *ngIf="isLoading" class="{{ isLoading ? 'loadingOverlay' : '' }}">
  <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div *ngIf="isLoadingDetails" class="loading-feedback">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7"
      animationDuration="0.5s">
  </p-progressSpinner>
</div>
<div class="programme-offered-cont">
  <div class="button-cont">
    <!-- <button label="Primary" class="p-button-create blue-outlined-btn" (click)="bulkUpload()">
      Bulk upload
    </button> -->
    <button pButton pRipple class="p-button-create blue-filled-btn" (click)="createNew()" label="Create New"></button>
    <p-sidebar [(visible)]="showBulkUpload" position="right" (onHide)="removeFile()" [dismissible]="false">
      <ng-template pTemplate="header">
        <div class="header-cont">
          <span>Bulk Upload</span>
          <button pbutton class="p-button-create blue-filled-btn" (click)="saveBulkUpdate('create')" type="submit">
            Save & Next
          </button>
        </div>
        <p-divider layout="horizontal"></p-divider>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="bulkUpload-cnt">
          <div class="download-excel">
            <span class="download-header">Dowload Bulk Upload Sample</span>
            <div class="download-body">
              <div class="csv-icon">
                <div class="icon">
                  <img src="../assets/programme-offered/csv.svg" alt="" />
                </div>
                <span>Sample_Bulk_Invite.csv</span>
              </div>
              <div (click)="sampleData('create')" class="download-icon">
                <i class="cursor-pointer pi pi-download"></i>
              </div>
            </div>
          </div>
          <div class="upload-excel">
            <span class="download-header">Upload Bulk Mapping File </span>
            <div class="bulkUpload-dropzone" ngx-dropzone [accept]="
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              " [multiple]="false" (change)="onSelectExcel($event, 'create')">
              <ngx-dropzone-label *ngIf="!uploadingFile">
                <div class="dropzone-label">
                  <em class="pi pi-cloud-upload"></em>
                  <p class="upload-text">
                    Click to
                    <span [style]="{ color: 'var(--fc-primary)' }">upload</span>
                    or Drag & Drop
                  </p>
                  <p class="format-text">.XLS, .XLSX Formate only</p>
                </div>
              </ngx-dropzone-label>
              <div *ngIf="uploadingFile">
                <p-progressSpinner [style]="{ height: '30px', width: '30px' }" strokeWidth="7" animationDuration="0.5s">
                </p-progressSpinner>
              </div>
              <div *ngIf="!uploadingFile">
                <ngx-dropzone-preview class="file-preview" ngProjectAs="ngx-dropzone-preview"
                  *ngFor="let f of mediaFile" [file]="f" [removable]="true" (removed)="onRemoveMedia(f)">
                  <ngx-dropzone-label class="dropzone-preview-label">{{ f.name }}
                  </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </div>
            </div>
          </div>
          <div class="records-container" *ngIf="showCreateReport" [style]="{
              background: 'rgba(52, 86, 255, 0.03)',
              borderRadius: '4px',
              width: '303px',
              height: '40px',
              marginTop: '25px',
              marginBottom: '25px',
              display: 'flex',
              alignItems: 'center'
            }">
            <div class="records" [style]="{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }">
              <div class="total" [style]="{
                  color: '#000000',
                  fontWeight: 500,
                  fontSize: '12px',
                  marginRight: '15px'
                }">
                <label class="file-name">Total records :
                  <span class="total-mark"> {{ totalCount }}</span>
                </label>
              </div>
              <div class="correct" [style]="{
                  fontWeight: 500,
                  fontSize: '12px',
                  marginRight: '15px'
                }">
                <label class="file-name" [style]="{ color: '#008000' }">Correct :
                  <span class="total-mark" [style]="{ color: '#000000' }">
                    {{ createdCount }}
                  </span>
                </label>
              </div>
              <div class="totalErrors" [style]="{
                  fontWeight: 500,
                  fontSize: '12px',
                  marginRight: '15px'
                }">
                <label class="file-name" [style]="{ color: '#FF5E5B' }">Error :
                  <span class="total-mark" [style]="{ color: '#000000' }">
                    {{ createErrorCount }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="notes" *ngIf="createErrorCount > 0">
            <label class="heading" [style]="{
                color: '#000000',
                fontWeight: 'bold',
                fontSize: '14px'
              }">Note :
              <span class="total-mark" [style]="{ fontWeight: 'lighter' }">
                Kindly resolve or remove all the error in the main file to
                proceed to the next step.
                <span [style]="{ color: '#3456FF', cursor: 'pointer' }" (click)="downloadReport('create')">Download
                  Error Report
                </span>
              </span>
            </label>
          </div>
        </div>
      </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="createSidebarShow" position="right" [dismissible]="false">
      <ng-template pTemplate="header">
        <div class="header-cont">
          <span>{{
            selectedOptions.length > 0 ? "Edit Field" : "Create new"
            }}</span>
          <button pbutton class="p-button-create blue-filled-btn" (click)="
              selectedOptions.length > 0 ? onEditFields() : onSubmitCreate()
            " type="submit">
            {{ selectedOptions.length > 0 ? "Update" : "Save & Next" }}
          </button>
        </div>
        <p-divider layout="horizontal"></p-divider>
      </ng-template>
      <ng-template pTemplate="body">
        <form class="createform" [formGroup]="createNewField" type="submit">
          <span class="p-float-label create-fields" *ngFor="let row of inputData; let i = index">
            <input
              *ngIf="(row.fieldtype === 'input' && (row.controlfield != 'specialization_minor')) && (row.controlfield === 'specialization' && !disableProgrammes)"
              formControlName="{{ row.controlfield }}" type="{{ row.type }}" pInputText />
            <label
              *ngIf="(row.fieldtype === 'input' && (row.controlfield != 'specialization_minor')) && (row.controlfield === 'specialization' && !disableProgrammes)"
              for="inputtext">{{ row.label }}
              <span class="imp" *ngIf="row.mandatory">*</span>
            </label>
            <input [ngStyle]="{'cursor': 'not-allowed'}"
              *ngIf="row.controlfield === 'specialization' && disableProgrammes" [readonly]="true"
              formControlName="{{ row.controlfield }}" type="{{ row.type }}" pInputText />
            <label *ngIf="row.controlfield === 'specialization' && disableProgrammes" for="inputtext">{{ row.label }}
              {{row.controlfield === 'specialization'}} {{!disableProgrammes}}
              <span class="imp" *ngIf="row.mandatory">*</span>
            </label>
            <input
              *ngIf="(row.controlfield === 'specialization_minor' || row.controlfield === 'specialization_major') && createNewField.get('is_dual_specialization')?.value"
              formControlName="{{ row.controlfield }}" type="{{ row.type }}" pInputText />
            <label
              *ngIf="(row.controlfield === 'specialization_minor' || row.controlfield === 'specialization_major') && createNewField.get('is_dual_specialization')?.value"
              for="inputtext">{{ row.label }}
              <span class="imp" *ngIf="row.mandatory">*</span>
            </label>
            <div class="dropdown-cont p-float-label">
                <p-dropdown *ngIf="row.fieldtype === 'dropdown'" optionLabel="label" optionValue="value" [filter]="true" filterBy="label" formControlName="{{ row.controlfield }}"
                  [options]="row.array" [autoDisplayFirst]="false" [autoDisplayFirst]="false">
                </p-dropdown>
                <label *ngIf="row.fieldtype === 'dropdown'" for="dropdown">{{ row.label }}
                  <span class="imp" *ngIf="row.mandatory">*</span>
                </label>
              <button *ngIf="row.fieldtype === 'dropdown' && row.controlfield === 'degree_name'" label="Primary"
                class="p-button-create blue-outlined-btn" (click)="addNew()">
                Add New
              </button>
            </div>

            <!-- <p-autoComplete *ngIf="row.controlfield === 'degree_type'" [virtualScrollItemSize]="34"
              formControlName="{{ row.controlfield }}" [suggestions]="degreeTypeOptions" field="degree_type"
              (completeMethod)="filterDegreeType($event)">
            </p-autoComplete>

            <p-autoComplete *ngIf="row.controlfield === 'degree_name'" [virtualScrollItemSize]="34"
              formControlName="{{ row.controlfield }}" [suggestions]="degreeNameOptions" field="degree_name"
              (completeMethod)="filterDegreeName($event)"></p-autoComplete>
            <label *ngIf="row.fieldtype === 'autocomplete'" for="autocomplete">{{ row.label }}
              <span class="imp" *ngIf="row.mandatory">*</span>
            </label> -->
            <div>
              <p-checkbox *ngIf="row.fieldtype === 'checkbox'" formControlName="is_dual_specialization"
                [binary]="true"></p-checkbox>
              <!-- <p-checkbox [binary]="true" [(ngModel)]="option.selectedAcademics"></p-checkbox> -->
              <label *ngIf="row.fieldtype === 'checkbox'" for="{{ row.label }}">{{ row.label }}</label>
            </div>
          </span>
        </form>
      </ng-template>
    </p-sidebar>
  </div>
  <div class="table-cont table-relative">
    <app-table
      [columns]="columns"
      [dataList]="dataList"
      [checkBox]="true"
      [dataCount]="dataCount"
      [pageLimits]="pageLimits"
      [totalPage]="totalPage"
      [allRowsSelected]="checkedAll"
      [action]="false"
      (openEdit)="openEditFiled($event)"
      (search)="searchValueChange($event)"
      (rowLength)="rowLengthChange($event)"
      (pageNo)="pageChange($event)"
      (selectedColumns)="selectedField($event)"
      (deletingData)="deleteData($event)"
      (fileFormat)="fileFormat($event)"
      [eventListen]="listenChange"
      [remValue]="'18'" 
      [download]="true" 
      [columnList]="true"
    ></app-table>
    <p-toast></p-toast>
  </div>
  <div class="card flex justify-content-center">
    <p-dialog header="Header" [(visible)]="visible" [style]="{ width: '30vw' }" (onHide)="closeDialog()">
      <ng-template pTemplate="header">
        <span class="text-xl font-bold header">Degree Name</span>
      </ng-template>
      <div class="card flex justify-content-center p-float-label">
        <input type="text" pInputText [(ngModel)]="enteredDegreeName">
        <label for="placedCountCompanies">Enter Degree Name</label>
      </div>
      <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-create blue-filled-btn" label="Save"
          (click)="saveDegreeName()"></button>
      </ng-template>
    </p-dialog>
  </div>