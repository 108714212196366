import { HttpService } from '../services/http.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { MessageService } from 'primeng/api';
import { Editor } from 'primeng/editor';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [MessageService],
})
export class HeaderComponent implements OnInit {
  @ViewChild('editor') editor!: Editor;
  openDialog: boolean = false;
  isLoading: boolean = false;
  actionOptions: any[] = [];
  profileSidebar: boolean = false;
  selectedImage: any =
    'https://pat-v2-stage.s3.amazonaws.com/sa_user_image/4f104692-d47d-4aeb-9e7f-fe12419e646d/default_image/41a93c06-7832-49d2-9545-95f31bb42ac6';
  profileFields: FormGroup = new FormGroup({});
  profileDetails: any[] = []
  patchedValue: FormGroup = new FormGroup({})
  imageChanged: boolean = false
  s3SignedUrl: any = '';
  userData: any = localStorage.getItem('user_details')

  constructor(
    private router: Router,
    private breadcrumb: NgDynamicBreadcrumbService,
    private messageService: MessageService,
    private httpService: HttpService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        // this.breadcrumb.updateBreadcrumb([{ label: 'Dashboard', url: '' }]);
      }
    })
    this.userData = JSON.parse(this.userData)
    this.selectedImage = this.userData && this.userData.logo ? this.userData.logo : 'https://pat-v2-stage.s3.amazonaws.com/sa_user_image/4f104692-d47d-4aeb-9e7f-fe12419e646d/default_image/41a93c06-7832-49d2-9545-95f31bb42ac6';
    this.actionOptions = [
      { label: 'View Profile', value: 'View Profile' },
      { label: 'Logout', value: 'Logout' },
    ];
    this.profileDetails = [
      {
        label: 'First Name',
        disable: true,
        type: 'input',
        controlfield: 'first_name',
        mandatory: true,
      },
      {
        label: 'Last Name',
        disable: true,
        type: 'input',
        controlfield: 'last_name',
        mandatory: true,
      },
      {
        label: 'Email',
        disable: true,
        type: 'input',
        controlfield: 'email',
        mandatory: true,
      },
      {
        label: 'Editor',
        disable: true,
        type: 'editor',
        controlfield: 'email_signature',
        mandatory: true,
      },
    ];
  }

  ngOnInit(): void {
    this.profileFields = this.createProfileFormGroup();
  }

  updateProfile(logo: any, email_signature: any) {
    this.selectedImage = logo ? logo : '';
    this.editor.getQuill().root.innerHTML = email_signature
      ? email_signature
      : '';
  }

  async signedUrl(payload: any): Promise<any> {
    return new Promise(async (reolved: any, rejected: any) => {
      try {
        let url: any = await this.httpService.post('/global/getSignedUrl', payload)
        reolved(url)
      } catch (error) {
        console.error(error);
        rejected(error);
      }
    });
  }

  async templateImageUpload(data: string): Promise<any> {
    return new Promise((resolved, rejected) => {
      const elem: any = document.createElement('div');
      elem.innerHTML = data;
      const imgarray = elem.getElementsByTagName('img');
      const promiseArray = [];
      for (const match of imgarray) {
        if (
          match.src.indexOf('amazonaws.com') === -1 &&
          match.src.indexOf('base64') !== -1
        ) {
          promiseArray.push(
            new Promise(async (res, rej) => {
              const pay = {
                Bucket_name: 'pat-dev',
                file_name: `email-template/${this.userData.super_admin_id
                  }/${uuidv4()}`,
                type: match.src.split(';base64')[0].split(':')[1],
                ACL: 'public-read',
              };
              this.s3SignedUrl = await this.signedUrl(pay);
              if (this.s3SignedUrl) {
                const blobData: any = this.dataURItoBlob(match.src, pay.type)
                let s3Response: any = await this.httpService.uploadUsingSignedUrl(this.s3SignedUrl['data'].response, blobData)
                let url =
                  'https://' +
                  pay.Bucket_name +
                  '.s3.amazonaws.com/' +
                  pay.file_name;
                match.src = url;
                res(url);
              }
            })
          );
        }
      }
      Promise.all(promiseArray).then((s3Url: any) => {
        resolved(elem.innerHTML);
      });
    });
  }

  dataURItoBlob(dataURI: any, type: any) {
    dataURI = dataURI.split('" ')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
  }

  async onEditProfile() {
    if (!this.isLoading) {
      let payload: any = {}
      for (let fields in this.patchedValue.controls) {
        if (
          this.profileFields.controls[fields].value !==
          this.patchedValue.controls[fields].value
        ) {
          payload[fields] = this.patchedValue.controls[fields].value;
        }
      }
      if (!Object.keys(payload).length && !this.imageChanged) {
        this.profileSidebar = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Validation',
          detail: 'Values are unchanged',
        });
        return;
      }
      this.isLoading = true;
      if (payload.email_signature) {
        let ele: any = this.editor.getQuill().root.innerHTML;
        let response: any = await this.templateImageUpload(ele);
        payload.email_signature = response;
      }
      if (this.imageChanged) {
        const type: any = this.selectedImage.split(';base64')[0].split(':')[1];
        let blobData: any = this.dataURItoBlob(this.selectedImage, type);
        let pay = {
          Bucket_name: 'pat-dev',
          file_name: `sa_user_image/${this.userData.super_admin_id}/${uuidv4()}`,
          type: type,
          ACL: 'public-read',
        };
        this.s3SignedUrl = await this.signedUrl(pay)
        let s3Response: any = await this.httpService.uploadUsingSignedUrl(this.s3SignedUrl['data'].response, blobData)
        if (s3Response && s3Response.status !== 200) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Prfile Image Upload Failed',
          });
          this.profileSidebar = false;
          this.isLoading = false;
          return;
        }
        payload.logo = `https://${pay.Bucket_name}.s3.amazonaws.com/${pay.file_name}`;
      }
      payload.super_admin_id = this.userData.super_admin_id
      let res: any = await this.httpService.put('/profile', payload)
      if (res && res.success && res.data) {
        const { data } = res
        this.messageService.add({
          severity: 'success', summary: 'Success', detail: 'Updated Successfully!'
        })
        this.isLoading = false
        this.profileSidebar = false
        this.userData = { ...data }
        localStorage.setItem('user_details', JSON.stringify(data))
      }
      else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Updation Failed!',
        });
        this.isLoading = false;
        this.profileSidebar = false;
      }

    }
  }

  createProfileFormGroup() {
    const formGroupConfig: any = {};
    this.profileDetails.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  async onClickAction(options: any) {
    if (options.label === 'View Profile') {
      this.userData = localStorage.getItem('user_details')
      this.userData = JSON.parse(this.userData)
      this.profileFields.patchValue(this.userData)
      this.profileSidebar = true
      setTimeout(() => {
        this.updateProfile(this.userData.logo, this.userData.email_signature)
      }, 10)
      this.patchedValue = _.cloneDeep(this.profileFields)
      return;
    }
    setTimeout(() => {
      this.router.navigateByUrl('/login');
    }, 0);
    for (let item in localStorage) {
      //Need to add condition If need not to delete every localstorage keys
      localStorage.removeItem(item);
    }
    this.router.navigateByUrl('/login');
    this.openDialog = false
  }

  onFileSelected(event: any, purpose: any): void {
    if (event.target.files && event.target.files[0]) {
      this.imageChanged = true;
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  onEditIcon() {
    this.profileDetails.map((ele: any) => {
      if (ele.label !== 'Email') {
        ele.disable = false;
      }
    });
  }

  onOpenDialog() {
    this.openDialog = !this.openDialog;
  }
}
