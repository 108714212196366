import { FileUploadService } from 'src/app/services/fileupload.service';
import { SuperAdminService } from '../services/super-admin.service';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GlobalServicesService } from '../services/global-service.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-colleges',
  templateUrl: './colleges.component.html',
  styleUrls: ['./colleges.component.css'],
})
export class CollegesComponent {
  @ViewChild('overlayPanel') overlaypanel: any;
  tableFilter: boolean = false;
  searchValue: any = '';
  columns: any[] = [];
  dataList: any[] = [];
  selectedFields: any = [];
  rowsize: number = 10;
  currentCollegeId: string = '';
  listenChange: boolean = true;
  pageAndRow: boolean = true
  showAction: boolean = true;
  isLoading: boolean = false;
  fieldSelectedForDownload: any[] = [];
  dataCount: number = 0;
  totalPage: number = 0;
  numericPattern = /^\d+$/;
  selectedPage: any = 1;
  searchColor: boolean = true;
  createSidebarShow: boolean = false;
  pageSize: number = 10;
  pageLimits: any[] = [];
  showFilter: boolean = false;
  showList: boolean = false;
  showDownload: boolean = false;
  inputData: any[] = [];
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  createNewField: FormGroup = new FormGroup({});
  formValid: boolean = false;
  indexnumber: number = 1;
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  actionOptions: any[] = [];
  selectedAction: any;
  openEditSidebar: boolean = false;
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  isFileRemoved: boolean = true;
  filePath: any;
  showCreateReport: boolean = false;
  updatedCount: number = 0;
  totalCount: number = 0;
  updateErrorCount: number = 0;
  updateErrorData: any[] = [];
  createdCount: number = 0;
  createErrorCount: number = 0;
  createErrorData: any[] = [];
  mediaFile: File[] = [];
  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
    actionId: new FormControl(''),
  });
  excel: any;
  uploadingFile: boolean = false;
  bucketData: any = {};
  fileBase64: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  sampleDataForOnboard: any[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private superAdminService: SuperAdminService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private http: HttpService
  ) {
    this.pageLimits = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];
    this.columns = [
      {
        field: 'college_name',
        header: 'College Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
    ];

    this.inputData = [
      {
        id: 1,
        label: 'College Name',
        type: 'text',
        controlfield: 'college_name',
        fieldtype: 'input',
        mandatory: true,
      },
    ];
    this.actionOptions = [
      { label: 'Edit', value: 'edit' },
      { label: 'Delete', value: 'delete' },
    ];
    this.searchValue = '';
    this.sampleDataForOnboard = [
      { 'College Name': 'VELLORE INSTITUTE OF TECHNOLOGY (VELLORE)' },
    ];
  }

  async ngOnInit() {
    this.createNewField = this.createFormGroupFromInputData();
    const body = { limit: this.rowsize, page: this.selectedPage };
    this.isLoading = true;
    const response = await this.fetchCollegeLists(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  }

  isChecked(label: any) {
    if (label.selected) {
      this.selectedFields.push(label.field);
    } else {
      const fieldIndex = this.selectedFields.indexOf(label.field);
      if (fieldIndex !== -1) {
        this.selectedFields.splice(fieldIndex, 1);
      }
    }
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchCollegeLists(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchCollegeLists(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchCollegeLists(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  async onSubmitCreate() {
    this.formValid = this.createNewField.valid;
    const payload: any = {};
    if (!this.formValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields',
      });
      return;
    }
    const obj: any = await this.assignAndValidation();
    payload.college_name = obj.college_name;
    this.isLoading = true;
    const invite = await this.collegeListImport(payload);
    this.createSidebarShow = !this.createSidebarShow;
    if (invite && invite.success) {
      const body = {
        limit: this.rowsize,
        page: this.selectedPage,
        search: this.searchValue,
      };
      const response = await this.fetchCollegeLists(body);
      if (response.success) {
        this.dataList = response.data.data;
        this.dataCount = response.data.count;
        this.totalPage = Math.ceil(this.dataCount / this.rowsize);
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: invite.data,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: invite.message,
      });
    }
    this.isLoading = false;
    return;
  }

  assignAndValidation(payload?: any) {
    let obj: any = {};
    for (const controlField in this.createNewField.controls) {
      obj[controlField] = this.createNewField.controls[controlField].value;
    }
    return obj;
  }

  async deleteData(event: any) {
    const college_list_id = _.map(event, 'college_list_id');
    this.isLoading = true;
    const bodyForDelete = { college_list_id: college_list_id };
    const deleteResponse = await this.deleteCollegeLists(bodyForDelete);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully Deleted',
    });
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    const response = await this.fetchCollegeLists(body);
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    this.checkedAll = false;
    this.isLoading = false;
    this.listenChange = false;
  }

  async fetchCollegeLists(body: any) {
    const fetchStudentsDetails = await this.http.post(
      '/collegeLists/fetch',
      body
    );
    return fetchStudentsDetails;
  }

  async collegeListImport(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/collegeLists/import',
      body
    );
    return fetchProgrammeDetails;
  }

  async collegeListUpdate(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/collegeLists/update',
      body
    );
    return fetchProgrammeDetails;
  }

  async deleteCollegeLists(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/collegeLists/delete',
      body
    );
    return fetchProgrammeDetails;
  }

  async selectedField(event: any) {
    this.fieldSelectedForDownload = _.map(event, 'field');
    this.isLoading = true;
    const body = {
      page: this.selectedPage,
      limit: this.rowsize,
      fields: this.fieldSelectedForDownload,
    };
    const response = await this.fetchCollegeLists(body);
    this.isLoading = false;
    this.downloadFile(response.data.data, this.downloadColumnAs, 'College-list');
  }

  downloadFile(response: any, fileType: any, fileName: any) {
    const worksheet = XLSX.utils.json_to_sheet(response);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: fileType === 'excel' ? 'xlsx' : 'csv',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, fileName, fileType);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], { type: FILE_TYPE });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  fileFormat(event: any) {
    this.downloadColumnAs = event;
  }

  bulkUpload() {
    this.showBulkUpload = true;
  }

  createNew() {
    this.createNewField.reset();
    this.selectedOptions = [];
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
  }

  openEditFiled(event: any) {
    this.currentCollegeId = '';
    this.selectedOptions = _.cloneDeep(event);
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 2) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Select only one row',
      });
      return;
    }
    this.createNewField.patchValue(this.selectedOptions[0]);
    this.currentCollegeId = this.selectedOptions[0].college_list_id;
    this.patchedValues = _.cloneDeep(this.createNewField);
    this.createSidebarShow = true;
  }

  async onEditFields() {
    let changedFields: any = {};
    const payload: any = {};
    for (const controlField in this.createNewField.controls) {
      changedFields[controlField] =
        this.createNewField.controls[controlField].value;
    }
    if (Object.keys(changedFields).length === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Fields are Unchanged',
      });
      this.createSidebarShow = !this.createSidebarShow;
      return;
    }
    if (this.createNewField.valid) {
      const obj: any = await this.assignAndValidation();
      payload.college_list_id = this.currentCollegeId;
      payload.college_name = obj.college_name;
      this.isLoading = true;
      const update = await this.collegeListUpdate(payload);
      this.createSidebarShow = !this.createSidebarShow;
      if (update && update.success) {
        const body = { limit: this.rowsize, page: this.selectedPage };
        const response = await this.fetchCollegeLists(body);
        if (response.success) {
          this.dataList = response.data.data;
          this.dataCount = response.data.count;
          this.totalPage = Math.ceil(this.dataCount / this.rowsize);
        }
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: update.data,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: update.message,
        });
      }
      this.createNewField = this.createFormGroupFromInputData();
      this.patchedValues = _.cloneDeep(this.createNewField);
      this.isLoading = false;
      // this.isLoading = true;
      this.listenChange = false;
      return;
    }
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Fill the mandatory fields',
    });
  }

  showHistory() {}

  saveBulkUpload() {}

  async saveBulkUpdate(type: any) {
    if (!this.isFileRemoved) {
      let body = { file: this.filePath };
      let endPoint = '/collegeLists/bulkUpload';
      this.isLoading = true;
      const response = await this.http.post(endPoint, body);
      if (response && response.success) {
        this.showCreateReport = true;
        this.createdCount = response.data.successLength;
        this.totalCount = response.data.totalRecord;
        this.createErrorCount = response.data.errorCount;
        this.createErrorData = response.data.errorDatas;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Validate',
          detail: response.message,
        });
      }
      this.isLoading = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Please upload the file',
      });
    }
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.bulkInviteForm.controls['file'].setValue('');
    this.isFileRemoved = true;
  }

  onSelectExcel(event: any, type: any) {
    this.excel = event.addedFiles;
    this.mediaFile.push(...event.addedFiles);
    this.isFileRemoved = false;
    this.handleUpload(type);
  }

  handleUpload(type: any) {
    const file = this.excel[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, type);
    };
  }

  async fileUpload(file: any, type: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${fileName
      .join()
      .replace(/\s/g, '')
      .replace(/[^\w\s]/gi, '')}.${fileExtension}`;
      this.filePath = `superadmin/colleges/${type}/${fileName}`;
      this.bucketData = {
      Bucket_name: this.globalService.bucketName,
      file_name: this.filePath,
      type: file.type,
    };
    const blobData: any = file;

    let url: any = await this.fileUploadService.postApiCall(
      '/global/getSignedUrl',
      this.bucketData
      );
    if (url && url.data) {
      const json = url;
      let r: any = await this.fileUploadService.uploadUsingSignedUrl(
        json.data.response,
        blobData
      );
      this.fileBase64 = `${this.s3Domain}/${this.globalService.bucketName}/${this.bucketData.file_name}`;
      this.uploadingFile = false;
    }
  }

  downloadReport(type: any) {
    if (type == 'create') {
      this.downloadFile(
        this.createErrorData,
        'excel',
        'College-list-create-error'
      );
    } else {
      this.downloadFile(
        this.updateErrorCount,
        'excel',
        'College-list-update-error'
      );
    }
  }

  sampleData(type: any) {
    if (type == 'create') {
      this.downloadFile(
        this.sampleDataForOnboard,
        'excel',
        'College-list-sample'
      );
    } else {
      let result: any = [{}];
      result[0]['primary_email'] = 'default@example.com';
      _.forEach(this.selectedFields, (field: any) => {
        if (
          field !== 'primary_email' &&
          this.sampleDataForOnboard[0].hasOwnProperty(field)
        ) {
          result[0][field] = this.sampleDataForOnboard[0][field];
        } else if (
          field === 'batch_name' ||
          field === 'degree_name' ||
          field === 'department_name' ||
          field === 'programme_name'
        ) {
          result[0]['batch_name'] = '2021-2025';
          result[0]['degree_name'] = 'Under Graduate';
          result[0]['programme_name'] = 'Bachelor of Engineering';
          result[0]['department_name'] = 'Civil Engineering';
        }
      });
      this.downloadFile(result, 'excel', 'Staff-list-sample');
    }
  }
}
