import { AppModule } from './../app.module';
import { Component, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SuperAdminService } from '../services/super-admin.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GlobalServicesService } from '../services/global-service.service';
import { FileUploadService } from '../services/fileupload.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-programme-offered',
  templateUrl: './programme-offered.component.html',
  styleUrls: ['./programme-offered.component.css'],
  providers: [MessageService],
})
export class ProgrammeOfferedComponent {
  @ViewChild('overlayPanel') overlaypanel: any;
  searchValue: any = '';
  pageAndRow: boolean = true
  columns: any[] = [];
  suggestionDegreeTypes: any[] = [];
  suggestionDegreeNames: any[] = [];
  dataList: any[] = [];
  rowsize: number = 10;
  spacePattern = /^\s*$/;
  programmeOptions: any[] = [];
  isLoading: boolean = false;
  showAction: boolean = false;
  fieldSelectedForDownload: any[] = [];
  dataCount: number = 0;
  totalPage: number = 0;
  visible: boolean = false;
  enteredDegreeName: string = '';
  searchColor: boolean = true;
  createSidebarShow: boolean = false;
  degreeTypeOptions: any[] = [];
  degreeNameOptions: any[] = [];
  pageSize: number = 10;
  pageLimits: any[] = [];
  disableProgrammes: boolean = false;
  selectedPage: any = 1;
  currentProgramme_id: string = '';
  showFilter: boolean = false;
  numericPattern = /^\d+$/;
  showList: boolean = false;
  selectedFields: any = [];
  showDownload: boolean = false;
  inputData: any[] = [];
  checkedAll: boolean = false;
  listenChange: boolean = true;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  createNewField: FormGroup = new FormGroup({});
  formValid: boolean = false;
  indexnumber: number = 1;
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  actionOptions: any[] = [];
  selectedAction: any;
  openEditSidebar: boolean = false;
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  isFileRemoved: boolean = true;
  isLoadingDetails: boolean = false
  filePath: any;
  showCreateReport: boolean = false;
  updatedCount: number = 0;
  totalCount: number = 0;
  updateErrorCount: number = 0;
  updateErrorData: any[] = [];
  createdCount: number = 0;
  createErrorCount: number = 0;
  createErrorData: any[] = [];
  mediaFile: File[] = [];
  countries: any;
  uploadedFile: any
  bulkInviteForm: FormGroup = new FormGroup({
    Bucket_name: new FormControl(''),
    file_name: new FormControl(''),
    link: new FormControl(''),
    source: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    eventCategory: new FormControl('', Validators.required),
    actionId: new FormControl(''),
  });
  excel: any;
  uploadingFile: boolean = false;
  bucketData: any = {};
  fileBase64: any;
  s3Domain: string = 'https://s3.amazonaws.com';
  sampleDataForOnboard: any[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private superAdminService: SuperAdminService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService,
    private http: HttpService
  ) {
    this.pageLimits = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];
    this.countries = {
      name: 'Afghanistan',
      code: 'AF',
    };
    this.columns = [
      {
        field: 'degree_name',
        header: 'Degree Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
        width: '18vw'
      },
      {
        field: 'degree_type',
        header: 'Degree Type',
        sortable: true,
        freezable: false,
        tableDefault: true,
        width: '18vw'
      },
      {
        field: 'is_dual_specialization',
        header: 'Dual Specialization',
        sortable: true,
        freezable: false,
        tableDefault: true,
        width: '18vw'
      },
      {
        field: 'specialization',
        header: 'Specialization',
        sortable: true,
        freezable: false,
        tableDefault: true,
        width: '18vw'
      },
      {
        field: 'specialization_minor',
        header: 'Specialization Minor',
        sortable: true,
        freezable: false,
        tableDefault: true,
        width: '18vw'
      },
    ];
    this.sampleDataForOnboard = [
      {
        'Degree Type': 'UG',
        'Degree Name': 'B.E.',
        'Dual Specialization': 'No',
        'Specialization': 'CSE',
        'Specialization Minor': '',
      },
    ];
    this.inputData = [
      {
        id: 1,
        label: 'Degree Name',
        type: 'dropdown',
        array: [],
        controlfield: 'degree_name',
        fieldtype: 'dropdown',
        mandatory: true,
      },
      {
        id: 2,
        label: 'Degree Type',
        type: 'dropdown',
        array: [{ label: 'UG', value: 'UG' },
        { label: 'PG', value: 'PG' },
        { label: 'PhD', value: 'PhD' }
      ],
        controlfield: 'degree_type',
        fieldtype: 'dropdown',
        mandatory: true,
      },
      {
        id: 3,
        label: 'Dual Specialization',
        type: 'text',
        controlfield: 'is_dual_specialization',
        fieldtype: 'checkbox',
        mandatory: false,
      },
      {
        id: 4,
        label: 'Specialization',
        type: 'text',
        controlfield: 'specialization',
        fieldtype: 'input',
        mandatory: true,
      },
      {
        id: 6,
        label: 'Specialization Minor',
        type: 'text',
        controlfield: 'specialization_minor',
        fieldtype: 'input',
        mandatory: false,
      },
    ];
    this.searchValue = '';
  }

  async ngOnInit() {
    this.createNewField = this.createFormGroupFromInputData();
    const body = { limit: 0, page: 10 };
    this.isLoading = true;
    const response = await this.fetchProgrammeDetails(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  }

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl(
        '',
        data.mandatory ? Validators.required : null
      );
    });
    return new FormGroup(formGroupConfig);
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchProgrammeDetails(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchProgrammeDetails(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  isChecked(label: any) {
    if (label.selected) {
      this.selectedFields.push(label.field);
    } else {
      const fieldIndex = this.selectedFields.indexOf(label.field);
      if (fieldIndex !== -1) {
        this.selectedFields.splice(fieldIndex, 1);
      }
    }
  }

  async selectedField(event: any) {
    this.fieldSelectedForDownload = _.map(event, 'field');
    this.isLoading = true;
    const body = {
      fields: this.fieldSelectedForDownload,
    };
    const response = await this.fetchProgrammeDetails(body);
    this.isLoading = false;
    this.downloadFile(response.data.data, this.downloadColumnAs, 'Programmes-list');
  }

  downloadFile(response: any, fileType: any, fileName: any) {
    const worksheet = XLSX.utils.json_to_sheet(response);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: fileType === 'excel' ? 'xlsx' : 'csv',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, fileName, fileType);
  }

  saveAsExcelFile(buffer: any, fileName: string, type: string): void {
    let FILE_TYPE =
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        : 'text/csv';
    let FILE_EXTENSION = type === 'excel' ? '.xlsx' : '.csv';
    const data: Blob = new Blob([buffer], { type: FILE_TYPE });
    saveAs(data, fileName + '_export_' + new Date().getTime() + FILE_EXTENSION);
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    const response = await this.fetchProgrammeDetails(body);
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    return;
  }

  bulkUpload() {
    this.showBulkUpload = true;
  }

  async createNew() {
    this.degreeTypeOptions = [];
    this.createNewField.reset();
    this.selectedOptions = [];
    this.degreeTypeOptions = _.uniq(_.map(this.dataList, 'degree_type'));
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
    await this.fetchDegrees();
    await this.mapDegree();
  }

  async mapDegree(){
    this.inputData[0].array = this.suggestionDegreeNames
  }

  async onSubmitCreate() {
    this.formValid = this.createNewField.valid;
    const payload: any = {};
    if (!this.formValid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill all the mandatory Fields',
      });
      return;
    }
    const obj: any = await this.assignAndValidation();
    if(obj.is_dual_specialization && obj.specialization_minor === ''){
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Kindly enter specialization minor',
      });
    }
    else if(this.spacePattern.test(obj.specialization)){
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Kindly enter valid data in specialization',
      });
    }
    else if(obj.is_dual_specialization && (this.spacePattern.test(obj.specialization_minor))){
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Kindly enter valid data in specialization minor',
      });
    }
    payload.degree_type = obj.degree_type;
    payload.degree_name = obj.degree_name;
    payload.specialization = obj.specialization;
    payload.specialization_minor = obj.specialization_minor ? obj.specialization_minor : null;
    payload.is_dual_specialization = obj.is_dual_specialization;
    this.isLoading = true;
    const invite = await this.programmeImport(payload);
    if (invite && invite.success) {
      this.createSidebarShow = !this.createSidebarShow;
      const body = { limit: this.rowsize, page: this.selectedPage };
      const response = await this.fetchProgrammeDetails(body);
      if (response.success) {
        this.dataList = response.data.data;
        this.dataCount = response.data.count;
        this.totalPage = Math.ceil(this.dataCount / this.rowsize);
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: invite.data,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: invite.message,
      });
    }
    this.isLoading = false;
    return;
  }

  assignAndValidation() {
    let obj: any = {};
    for (const controlField in this.createNewField.controls) {
      obj[controlField] = this.createNewField.controls[controlField].value;
    }
    return obj;
  }

  async fetchDegrees(){
    this.isLoading = true;
    const response = await this.fetchDegree()
    this.isLoading = false
    if(response.success){
      this.suggestionDegreeNames = response.data
    }
  }

  async fetchProgrammeDetails(body: any) {
    const fetchStudentsDetails = await this.http.post(
      '/programmesOffered/fetch',
      body
    );
    return fetchStudentsDetails;
  }

  async programmeImport(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/programmesOffered/import',
      body
    );
    return fetchProgrammeDetails;
  }

  async addDegree(body: any) {
    const addDegree = await this.http.post(
      '/programmesOffered/addDegree',
      body
    );
    return addDegree;
  }

  async fetchDegree() {
    const fetchProgrammeDetails = await this.http.get(
      '/programmesOffered/fetchDegree'
    );
    return fetchProgrammeDetails;
  }

  async programmeUpdate(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/programmesOffered/update',
      body
    );
    return fetchProgrammeDetails;
  }

  async openEditFiled(event: any) {
    this.degreeTypeOptions = [];
    this.degreeTypeOptions = _.uniq(_.map(this.dataList, 'degree_type'));
    this.currentProgramme_id = '';
    this.selectedOptions = _.cloneDeep(event);
    await this.fetchDegrees();
    await this.mapDegree();
    console.log('this.selectedOptions', this.selectedOptions)
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 2) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Select only one row',
      });
      return;
    }
    const { degree_name, degree_type } = this.selectedOptions[0];
    console.log('this.selectedOptions[0]', this.selectedOptions[0])
    // this.selectedOptions[0]!.degree_name = { degree_name: degree_name };
    // this.selectedOptions[0]!.degree_type = { degree_type: degree_type }
    this.selectedOptions[0]!.is_dual_specialization = this.selectedOptions[0]!.is_dual_specialization === 'Yes' ? true : false
    this.createNewField.patchValue(this.selectedOptions[0]);
    console.log('this.createNewField', this.createNewField)
    this.currentProgramme_id = this.selectedOptions[0].programme_id;
    this.patchedValues = _.cloneDeep(this.createNewField);
    this.createSidebarShow = true;
    this.disableProgrammes = true;
  }

  async onEditFields() {
    let changedFields: any = {};
    const payload: any = {};
    for (const controlField in this.createNewField.controls) {
      changedFields[controlField] =
        this.createNewField.controls[controlField].value;
    }
    if (Object.keys(changedFields).length === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Fields are Unchanged',
      });
      this.createSidebarShow = !this.createSidebarShow;
      return;
    }
    if (this.createNewField.valid) {
      const obj: any = await this.assignAndValidation();
      const degreeType =
        typeof obj.degree_type === 'string'
          ? obj.degree_type
          : obj.degree_type.degree_type;
      const degreeName =
        typeof obj.degree_name === 'string'
          ? obj.degree_name
          : obj.degree_name.degree_name;
      payload.programme_id = this.currentProgramme_id;
      payload.degree_type = degreeType;
      payload.degree_name = degreeName;
      payload.specialization = obj.specialization;
      payload.specialization_minor = obj.specialization_minor ? obj.specialization_minor : null;
      payload.is_dual_specialization = obj.is_dual_specialization;
      this.isLoading = true;
      const update = await this.programmeUpdate(payload);
      this.disableProgrammes = false;
      if (update && update.success) {
        this.createSidebarShow = !this.createSidebarShow;
        const body = { limit: this.rowsize, page: this.selectedPage };
        const response = await this.fetchProgrammeDetails(body);
        if (response.success) {
          this.dataList = response.data.data;
          this.dataCount = response.data.count;
          this.totalPage = Math.ceil(this.dataCount / this.rowsize);
        }
        this.createNewField = this.createFormGroupFromInputData();
        this.patchedValues = _.cloneDeep(this.createNewField);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: update.data as any,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: update.message,
        });
      }
      this.listenChange = false;
      this.isLoading = false;
      return;
    }
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Fill the mandatory fields',
    });
  }

  async deleteData(event: any) {
    const programme_id = _.map(event, 'programme_id');
    this.isLoading = true;
    const bodyForDelete = { programme_id: programme_id };
    const deleteResponse = await this.deleteProgrammes(bodyForDelete);
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Successfully Deleted',
    });
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    const response = await this.fetchProgrammeDetails(body);
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
    this.checkedAll = false;
    this.isLoading = false;
    this.listenChange = false;
  }

  async deleteProgrammes(body: any) {
    const fetchProgrammeDetails = await this.http.post(
      '/programmesOffered/delete',
      body
    );
    return fetchProgrammeDetails;
  }

  fileFormat(event: any) {
    this.downloadColumnAs = event;
  }

  showHistory() {}

  saveBulkUpload() {}

  async saveBulkUpdate(type: any) {
    if (!this.isFileRemoved) {
      let body = { file: this.filePath };
      let endPoint = '/programmesOffered/bulkImport';
      this.isLoading = true;
      const response = await this.http.post(endPoint, body);
      console.log('response', response)
      if (response && response.success) {
        this.showCreateReport = true;
        this.createdCount = response.data.successLength;
        this.totalCount = response.data.totalRecord;
        this.createErrorCount = response.data.errorCount;
        this.createErrorData = response.data.errorDatas;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Validate',
          detail: response.message,
        });
      }
      this.isLoading = false;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Please upload the file',
      });
    }
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.bulkInviteForm.controls['file'].setValue('');
    this.isFileRemoved = true;
  }

  onSelectExcel(event: any, type: any) {
    this.excel = event.addedFiles;
    this.mediaFile.push(...event.addedFiles);
    this.isFileRemoved = false;
    this.handleUpload(type);
  }

  handleUpload(type: any) {
    const file = this.excel[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, type);
    };
  }

  async fileUpload(file: any, type: any) {
    this.uploadingFile = true;
    let fileName = file.name.split('.');
    let fileExtension = fileName.pop();
    fileName = `${fileName
      .join()
      .replace(/\s/g, '')
      .replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    this.filePath = `superadmin/programme_offered/${type}/${fileName}`;
    this.bucketData = {
      Bucket_name: this.globalService.bucketName,
      file_name: this.filePath,
      type: file.type,
    };
    const blobData: any = file;

    let url: any = await this.http.post(
      '/global/getSignedUrl',
      this.bucketData
      );
    if (url && url.data) {
      const json = url;
      let r: any = await this.fileUploadService.uploadUsingSignedUrl(
        json.data.response,
        blobData
      );
      this.fileBase64 = `${this.s3Domain}/${this.globalService.bucketName}/${this.bucketData.file_name}`;
      this.uploadingFile = false;
    }
  }

  downloadReport(type: any) {
    if (type == 'create') {
      this.downloadFile(
        this.createErrorData,
        'excel',
        'Programme-offered-create-error'
      );
    } else {
      this.downloadFile(
        this.updateErrorCount,
        'excel',
        'Programme-offered-update-error'
      );
    }
  }

  sampleData(type: any) {
    if (type == 'create') {
      this.downloadFile(
        this.sampleDataForOnboard,
        'excel',
        'Programme-offered-sample'
      );
    }
  }

  filterDegreeType(event: any) {
    console.log('event', event)
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < (this.suggestionDegreeTypes as any[]).length; i++) {
      let degree = (this.suggestionDegreeTypes as any[])[i];
      let sanitizedDegreeType = degree.replace(/[\s.,-]/g, '');
      if (sanitizedDegreeType.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const degrees = {degree_type: degree}
        filtered.push(degrees);      
      }
    }

    this.degreeTypeOptions = filtered;
  }

  filterDegreeName(event: any) {
    console.log('event', event)
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < (this.suggestionDegreeNames as any[]).length; i++) {
      let degree = (this.suggestionDegreeNames as any[])[i];
      let sanitizedDegreeName = degree.replace(/[\s.,-]/g, '');
      if (sanitizedDegreeName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const degrees = {degree_name: degree}
        filtered.push(degrees);
        console.log('filtered', filtered)
      }
    }

    this.degreeNameOptions = filtered;
  }

  async saveDegreeName(){
    if(this.enteredDegreeName === ''){
      return this.messageService.add({
        severity: 'error',
        summary: 'Validation',
        detail: "Kindly enter degree name!",
      });
    }
    if (this.spacePattern.test(this.enteredDegreeName)) {
      return this.messageService.add({
        severity: 'error',
        summary: 'Validation',
        detail: "Kindly enter degree name!",
      });
    }
    this.isLoading = true
    const body = {degree_name: this.enteredDegreeName}
    const response = await this.addDegree(body)
    this.isLoading = false
    if(response.success){
      await this.fetchDegrees()
      await this.mapDegree();
      this.visible = false
      this.isLoading = false
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: "Degree Name Added Successfully",
      });
      this.isLoadingDetails = false
    }
    else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: response.message,
      });
    }
  }

  addNew(){
    this.visible = true
    this.isLoadingDetails = true
    this.enteredDegreeName = ''
  }

  closeDialog(){
    this.isLoadingDetails = false
    this.enteredDegreeName = ''
  }

  removeFile(){
    this.onRemoveMedia(this.mediaFile[0])
    this.showCreateReport = false;
    this.createErrorCount = 0
  }
}
