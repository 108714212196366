import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  constructor(private http: HttpService) {}

  createAttributes(payload: any) {
    try {
      const data = this.http.post('/attributes', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }

  fetchAttributes(payload: any) {
    try {
      const data = this.http.post('/attributes/fetch', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }

  updateAttributes(payload: any) {
    try {
      const data = this.http.put('/attributes', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }

  deleteAttributes(payload: any) {
    try {
      const data = this.http.delete('/attributes', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
