import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-creation',
  templateUrl: './field-creation.component.html',
  styleUrls: ['./field-creation.component.css'],
})
export class FieldCreationComponent implements OnInit {
  selectedTab: number | null = 1;
  tabs: string[] = ['Attributes'];

  ngOnInit() {}
  toggleTabs(tabSelect: number) {
    this.selectedTab = tabSelect;
  }
}
