import { SuperAdminService } from '../services/super-admin.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppModule } from '../app.module';
import { GlobalServicesService } from '../services/global-service.service';

@Component({
  selector: 'app-campus-login',
  templateUrl: './campus-login.component.html',
  styleUrls: ['./campus-login.component.css'],
})
export class CampusLoginTemplateComponent implements OnInit, OnChanges {
  shouldRerunContainer: boolean = true;
  colleges: any = [];
  images: any = [];
  play: boolean = true;
  autoPlay: boolean = true;
  isLoading: boolean = false;
  clicked: boolean = false;
  errorMessage: string = '';
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  loginSection = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });
  invalidCred: boolean = false;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  filteredColleges: any[] = [];
  selectedCollege: any;
  passwordShow: boolean = false;
  bounceTrue: boolean = true;
  loginPage: boolean = false;
  ballTouch: boolean = true;
  viewLogin: boolean = false;
  login: boolean = true;
  campus: boolean = false;
  height: string = window.innerHeight.toString() + 'px';
  constructor(
    private router: Router,
    private superAdminService: SuperAdminService,
    private globalService: GlobalServicesService,
    private appmodule: AppModule
  ) {}
  schoolResponse: any;

  async ngOnInit() {
    this.globalService.getImages().then((images) => {
      this.images = images;
    });
    this.loginRespondDone();
  }

  ngOnChanges(): void {}

  loginRespondDone() {
    setTimeout(() => {
      this.bounceTrue = false;
      this.ballTouch = false;
      this.viewLogin = true;
    }, 2000); // Delay the function execution for 5000 milliseconds (5 seconds)
    setTimeout(() => {
      this.viewLogin = false;
      this.loginPage = true;
    }, 3000);
  }

  onResize() {
    this.height = '100vh';
  }

  filterCollege(event: any) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.colleges.length; i++) {
      let college = this.colleges[i];
      if (college.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(college);
      }
    }

    this.filteredColleges = filtered;
  }

  toggleShowHidePwd() {
    let x: any = document.getElementsByClassName(
      'password-input-with-image'
    )[0];
    if (x.type === 'password') {
      x.type = 'text';
      this.passwordShow = true;
    } else {
      x.type = 'password';
      this.passwordShow = false;
    }
  }

  async signIn() {
    this.clicked = true;
    this.loginSection.get('email')?.markAsTouched();
    this.loginSection.get('password')?.markAsTouched();
    if (this.loginSection.valid) {
      this.isLoading = true;
      let payload: any = {
        email: this.loginSection.value.email,
        password: this.loginSection.value.password,
      };
      let response = await this.superAdminService.validUser(payload);
      console.log("Response", response)
      this.isLoading = false;
      if (response.success) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem(
          'user_details',
          JSON.stringify(response.data.data)
        );
        this.router.navigate(['/dashboard']);
      } else {
        this.invalidCred = true;
        this.errorMessage = 'Invalid Email or Password';
      }
    }
  }
}
