<div class="headerCont">
    <div class="rightcont">
        <app-ng-dynamic-breadcrumb class="breadCrumb" bgColor="var(--clr-white)" fontSize="16px"
        fontColor="var(--fc-light-dark)" symbol=">"></app-ng-dynamic-breadcrumb>
    </div>
    <p-sidebar [appendTo]="'body'" [style]="{width: '40vw'}" [(visible)]="profileSidebar" position="right">
        <ng-template pTemplate="header">
            <div class="header-cont">
                <span style="font-size: 16px;">Profile View/Edit</span>
                <button pbutton class="p-button-create blue-filled-btn" (click)="onEditProfile()" type="submit">Save
                    & Next</button>
            </div>
            <p-divider [ngStyle]="{'width': '100%'}" layout="horizontal"></p-divider>
        </ng-template>
        <ng-template pTemplate="body">
            <ng-container *ngIf="isLoading ; then loader ;else nonLoader"></ng-container>
            <ng-template #loader>
                <p-progressSpinner></p-progressSpinner>
            </ng-template>
            <ng-template #nonLoader>
                <div class="flex">
                    <input #fileInput type="file" (change)="onFileSelected($event , 'school')" accept="image/*"
                        style="display: none;">
                    <div *ngIf="selectedImage" class="image-preview">
                        <img [src]="selectedImage" alt="Preview">
                    </div>
                    <button [ngClass]="{ 'side-upload': selectedImage}" label="Primary"
                        class="p-button-create blue-outlined-btn" (click)="fileInput.click()">
                        {{selectedImage ? 'Re-Upload' : 'Upload'}}
                    </button>
                </div>
                <div style="display: flex; flex-direction: column ;gap: 5px; margin-top: 30px;" >
                    <div style="width: 30vw; display: flex; justify-content: space-between;">
                        <span>Personal Information</span>
                        <i class="pi pi-user-edit" style="color: var(--clr-primary);cursor: pointer;"
                            (click)="onEditIcon()" pTooltip="Edit" [tooltipPosition]="'top'"></i>
                    </div>
                    <form style="display: flex; flex-direction: column;" class="createform" [formGroup]="patchedValue"
                        type="submit" id="create-student">
                        <div class="flex-container" style="gap: 20px;" id="input-width">
                            <ng-container *ngFor="let row of profileDetails; let i = index">
                                <span *ngIf="row.type !== 'editor'" class="create-fields"
                                    style="display: flex; gap: 10px; font-size: 14px;">
                                    <label for="inputtext">{{row.label}}<span class="imp">*</span></label>
                                    <input style="width: 30vw;font-size: 14px;"
                                        [ngStyle]="{'cursor': !row.disable ? 'auto' : 'not-allowed'}"
                                        formControlName="{{row.controlfield}}" pInputText [readonly]="row.disable">
                                </span>
                                <p-editor #editor *ngIf="row.type === 'editor'" formControlName="email_signature"
                                    [readonly]="row.disable" [style]="{ height: '200px' }">
                                </p-editor>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </ng-template>
            <p-toast></p-toast>
        </ng-template>
    </p-sidebar>
    <div class="leftcont">
        <!-- <img src="../../assets/headers/Union.svg" alt="notifications" title="notifications"> -->
        <div class="user-profile"  style="cursor: pointer;" (click)="onOpenDialog()">
            <div class="user-image">
                <img [src]="selectedImage">
                <p-dialog [(visible)]="openDialog" [style]="{width: '10vw'}">
                    <div *ngFor="let options of actionOptions">
                        <span class="action-option" (click)="onClickAction(options)">{{options.label}}</span>
                    </div>
                </p-dialog>
            </div>
            <div class="user-name">{{userData ? userData?.first_name+' '+userData?.last_name : ''}}</div>
        </div>
    </div>
</div>
<p-toast></p-toast>