<div>
    <div class="body-cont" *ngIf="path != '/login'">
        <div class = "side-nav">
            <app-side-nav></app-side-nav>
        </div>
        <div class = "main-container">
            <div class = "main-header">
                <app-header></app-header>
            </div>
            <div class = "main-body">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<div *ngIf="path === '/login'">
    <router-outlet></router-outlet>
</div>
<p-toast></p-toast>



