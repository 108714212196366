<div (window:resize)="onResize()" class="login">
  <div class="ball-wrapper">
    <div class="ball-container">
      <div class="ball" *ngIf="bounceTrue"></div>
      <div class="ballTouch" *ngIf="ballTouch"></div>
      <div class="ballZoom" *ngIf="viewLogin"></div>
    </div>
  </div>
  <div id="login-page-id" class="login-page" *ngIf="loginPage">
    <div id="logo-container-id" class="logo-container">
      <img class="iamneo-icon" src="assets/iamneo-icon.jpg" alt="My Image" />
    </div>
    <div class="form-container" *ngIf="campus">
      <div class="form-container">
        <div class="campus-input-button">
          <div class="campus-container">
            <label class="campus">Campus name</label>
            <p-autoComplete
              [(ngModel)]="selectedCollege"
              [suggestions]="filteredColleges"
              (completeMethod)="filterCollege($event)"
              field="name"
              [minLength]="3"
              class="my-suggestion-box"
            ></p-autoComplete>
          </div>
          <button type="button" class="next-button">Next</button>
        </div>
      </div>
    </div>
    <div id-="form-container-id" class="form-container" *ngIf="login">
      <div class="login-form-container">
        <div class="campus-input-button">
          <div class="campus-container">
            <div id="college-name-logo" class="mail-dropdown">
              <h1 class="college-name">LOGIN</h1>
            </div>
            <div
              id="error-msg-id"
              *ngIf="invalidCred"
              class="invalid-cred-container invalid-cred"
            >
              <span
                [ngClass]="[invalidCred ? 'visible-element' : 'hidden-element']"
              >
                {{ errorMessage }}
              </span>
            </div>
            <form
              id="mail-form-id"
              [formGroup]="loginSection"
              class="email-password-cant"
            >
              <span id="mail-span-id" class="p-float-label email-cant">
                <input
                  formControlName="email"
                  [required]="true"
                  class="email-input-with-image"
                  [ngClass]="{
                    'p-filled': loginSection.value.email,
                    'dirty-input':
                      (!loginSection.get('email')?.valid &&
                        loginSection.get('email')?.touched) ||
                      invalidCred
                  }"
                  style="min-width: 100% !important"
                  type="email"
                  id="mail-input-id"
                  pInputText
                  (click)="invalidCred = false"
                />
                <label
                  for="inputtext"
                  [ngClass]="[
                    !loginSection.get('email')?.valid &&
                    loginSection.get('email')?.touched
                      ? 'touched-email'
                      : invalidCred
                      ? 'invalid-credentials'
                      : ''
                  ]"
                  >Email
                </label>
              </span>
              <div
                id="mail-err-msg-1"
                *ngIf="loginSection.get('email')?.hasError('pattern')"
                class="error-msg error-msg-cant"
              >
                <span
                  [ngClass]="[
                    !loginSection.get('email')?.valid &&
                    loginSection.get('email')?.touched &&
                    loginSection.get('email')?.dirty
                      ? 'visible-element'
                      : 'hidden-element'
                  ]"
                >
                  Enter a valid Email ID
                </span>
              </div>
              <div
                id="mail-err-msg-2"
                class="error-msg"
                *ngIf="clicked && loginSection.get('email')?.errors"
              >
                <p *ngIf="loginSection.get('email')?.errors?.['required']">
                  Email is required
                </p>
              </div>
              <span id="password-span-id" class="p-float-label password-cant">
                <input
                  formControlName="password"
                  [required]="true"
                  class="password-input-with-image passwordInput"
                  [ngClass]="{
                    'p-filled': loginSection.value.password,
                    'dirty-input':
                      (!loginSection.get('password')?.valid &&
                        loginSection.get('password')?.touched) ||
                      invalidCred
                  }"
                  style="min-width: 100% !important"
                  type="password"
                  id="password-input-id"
                  pInputText
                  (keyup.enter)="signIn()"
                  (click)="invalidCred = false"
                />
                <label
                  for="inputtext"
                  [ngClass]="[
                    !loginSection.get('password')?.valid &&
                    loginSection.get('password')?.touched
                      ? 'touched-password'
                      : invalidCred
                      ? 'invalid-credentials'
                      : ''
                  ]"
                  >Password
                </label>
              </span>
              <div
                id="password-err-msg"
                *ngIf="
                  !loginSection.get('password')?.valid &&
                  loginSection.get('password')?.touched
                "
                class="error-msg error-msg-cant"
              >
                <span
                  [ngClass]="[
                    !loginSection.get('password')?.valid &&
                    loginSection.get('password')?.touched
                      ? 'visible-element'
                      : 'hidden-element'
                  ]"
                  >Password is required</span
                >
              </div>
              <div id="checkbox-forgot-id" class="checkbox-forgot-password">
                <div class="checkbox-show-password">
                  <p-checkbox
                    (onChange)="toggleShowHidePwd()"
                    [binary]="true"
                  ></p-checkbox>
                  <label for="show-password">Show Password</label>
                </div>
              </div>
              <span
                id="sign-in-id"
                class="sigin-btn"
                [ngClass]="[
                  loginSection.valid ? 'enable-signin' : 'disable-signin'
                ]"
                (click)="signIn()"
                type="submit"
              >
                <span *ngIf="!isLoading">Sign in</span>
                <span class="sign-btn-load" *ngIf="isLoading">
                  <p-progressSpinner
                    class="signin-spinner"
                    [style]="{ height: '20px', width: '20px' }"
                    strokeWidth="7"
                    animationDuration="0.5s"
                  >
                  </p-progressSpinner>
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-container">
      <div class="side-container-image">
        <div class="extra-div"></div>
        <h1 class="banner-container-title">SUPER ADMIN</h1>
        <img
          class="extra-icon-top"
          src="assets/extra-top-right.png"
          alt="My Image"
        />
        <div class="extra-icon-bottom">
          <img src="assets/extra-bottom-right.png" alt="Your Image" />
        </div>
        <p-galleria
          *ngIf="shouldRerunContainer"
          [value]="images"
          [responsiveOptions]="responsiveOptions"
          [containerStyle]="{ 'max-width': '640px' }"
          [numVisible]="5"
          [showThumbnails]="false"
          [showIndicators]="true"
          [transitionInterval]="3000"
          [circular]="true"
          [autoPlay]="play"
          class="galleria"
        >
          <ng-template pTemplate="item" let-item>
            <img
              [src]="item.itemImageSrc"
              style="width: 100%; display: block"
            />
            <h1 class="slide-content">{{ item.slideText }}</h1>
          </ng-template>
        </p-galleria>
      </div>
    </div>
  </div>
  <div></div>
</div>
