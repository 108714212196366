<div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>

<div class="account-creation">
    <div class="create-cnt">
        <button pButton (click)="navigateToCreateAcc()" class="p-button-create blue-filled-btn">Create New</button>
    </div>

    <div class="table-cont table-relative">
        <app-table [columns]="columns" [dataList]="dataList" [dataCount]="dataCount" [pageLimits]="pageLimits"
          [actionOptions]="actionOptions" [totalPage]="totalPage" [action]="false"
          (search)="searchValueChange($event)" [checkBox]="false"
          (rowLength)="rowLengthChange($event)" (pageNo)="pageChange($event)" (onRowClick)="onRowClick($event)"
          (handleRedirect)="handleRedirect($event)"
        ></app-table>
      </div>
<p-toast></p-toast>
</div>