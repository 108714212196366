import { environment } from '../../src/app/environments/environment';

let assets_bucket;
if (environment.production) {
  assets_bucket = 'pat-dev';
} else {
  assets_bucket = 'pat-dev';
};

const prefix = `https://storage.cloud.google.com`;

//bucket
const assetsBucket = `/${prefix}/${assets_bucket}`;
const localAssets = `/assets`;

// path
const sampleData = `${assetsBucket}/sample_data/`

//images
const bannerPattern = `${localAssets}/pattern.svg`;


export {
    bannerPattern,
    sampleData
};
