export enum Eglobal{
    VIEW = 'View',
    BTNCANCEL = 'Cancel',
    BTNAPPLY = 'Apply',
    MAPPROGRAMME = 'Map Programme',
    INVITE = 'Invite',
    EDITFIELD = 'Edit Field',
    UPDATE = 'Update',
    SAVEANDNEXT = 'Save & Next',
    CALENDAR = 'Calendar View',
    LIST = 'List View',  
    VIEWSTUDENT = 'View Students', 
    STATUS= 'Status',
    ACADEMICYEAR = 'Pass out year',
    CREATEDRIVE = 'Create',
    SALARY  = 'Salary',
    STIPEND = 'Stipend',
    SAVE = 'Save'
}

export const GBtnProperties = {
    applyFilter: {
        btnName: 'Apply Filter',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    },
    cancelBtn: {
        btnName: 'Cancel',
        fontColor: 'var(--clr-primary)',
        background: 'var(--clr-white)',
        border: '1px solid var(--clr-primary)',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '14px',
        height: '100%',
        borderRadius: '4px',
        marginleft: '0px',
        padding: '0px 10px',
        cursor: 'pointer'
    }
}

export enum PlatformStatus {
    DISABLED = 0,
    ENABLED = 1
}

export enum ProfileStatus {
    INVITED = 0,
    JOINED = 1,
    PUBLISHED = 2,
    APPROVED = 3,
    REJECTED = 4
}

export enum PlacementInterest {
    NO = 0,
    YES = 1
}

export enum EnumDriveStatus {
    INACTIVE = 0,
    ACTIVE = 1
}

export enum TableDefaults {
    student = 'student-table',
    drive = 'drive-table'
}

export enum MarkTypeToPercentageCGPA {
    tenth_mark = 'tenth_percentage_CGPA',
    twelfth_mark = 'twelfth_percentage_CGPA',
    ug_mark = 'ug_percentage_CGPA',
    pg_mark = 'pg_percentage_CGPA',
    diploma_mark = 'diploma_percentage_CGPA',
  }