import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalServicesService {
  bucketName: string = '';
  api_url = environment.HOST_LINK;
  private patchedValue = new BehaviorSubject<any>({});
  currentPatchValue = this.patchedValue.asObservable();
  public prefix = `https://storage.cloud.google.com`;

  constructor(private http: HttpClient) {
    let nodeEnv = environment.NODE_ENV;
    if (nodeEnv === 'development' || nodeEnv === 'acc') {
      this.bucketName = 'pat-dev';
    } else if (nodeEnv === 'prod') {
      this.bucketName = 'pat-dev';
    }
  }

  changePatchValue(message: any) {
    this.patchedValue.next(message);
  }

  getData() {
    return [
      {
        itemImageSrc: 'assets/slide1.png',
        slideText: 'Powerful software, simplified experience',
        alt: 'Description for Image 1',
        title: 'Title 1',
      },
      {
        itemImageSrc: 'assets/slide2.png',
        slideText: 'Effortless management of placement processes',
        alt: 'Description for Image 2',
        title: 'Title 2',
      },
      {
        itemImageSrc: 'assets/slide3.png',
        slideText: 'Transforming placement adminstration with technology',
        alt: 'Description for Image 3',
        title: 'Title 3',
      },
    ];
  }

  getImages() {
    return Promise.resolve(this.getData());
  }

  getColleges() {
    return this.http
      .get<any>('assets/college.json')
      .toPromise()
      .then((res) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  getCurrencyCodes() {
    const currencies = [
      { option: 'United Arab Emirates Dirham', value: 'AED' },
      { option: 'Afghan Afghani', value: 'AFN' },
      { option: 'Albanian Lek', value: 'ALL' },
      { option: 'Armenian Dram', value: 'AMD' },
      { option: 'Netherlands Antillean Guilder', value: 'ANG' },
      { option: 'Angolan Kwanza', value: 'AOA' },
      { option: 'Argentine Peso', value: 'ARS' },
      { option: 'Australian Dollar', value: 'AUD' },
      { option: 'Aruban Florin', value: 'AWG' },
      { option: 'Azerbaijani Manat', value: 'AZN' },
      { option: 'Bosnia-Herzegovina Convertible Mark', value: 'BAM' },
      { option: 'Barbadian Dollar', value: 'BBD' },
      { option: 'Bangladeshi Taka', value: 'BDT' },
      { option: 'Bulgarian Lev', value: 'BGN' },
      { option: 'Bahraini Dinar', value: 'BHD' },
      { option: 'Burundian Franc', value: 'BIF' },
      { option: 'Bermudian Dollar', value: 'BMD' },
      { option: 'Brunei Dollar', value: 'BND' },
      { option: 'Bolivian Boliviano', value: 'BOB' },
      { option: 'Brazilian Real', value: 'BRL' },
      { option: 'Bahamian Dollar', value: 'BSD' },
      { option: 'Bhutanese Ngultrum', value: 'BTN' },
      { option: 'Botswanan Pula', value: 'BWP' },
      { option: 'Belarusian Ruble', value: 'BYN' },
      { option: 'Belize Dollar', value: 'BZD' },
      { option: 'Canadian Dollar', value: 'CAD' },
      { option: 'Congolese Franc', value: 'CDF' },
      { option: 'Swiss Franc', value: 'CHF' },
      { option: 'Chilean Peso', value: 'CLP' },
      { option: 'Chinese Yuan', value: 'CNY' },
      { option: 'Colombian Peso', value: 'COP' },
      { option: 'Costa Rican Colón', value: 'CRC' },
      { option: 'Cuban Peso', value: 'CUP' },
      { option: 'Cape Verdean Escudo', value: 'CVE' },
      { option: 'Czech Republic Koruna', value: 'CZK' },
      { option: 'Djiboutian Franc', value: 'DJF' },
      { option: 'Danish Krone', value: 'DKK' },
      { option: 'Dominican Peso', value: 'DOP' },
      { option: 'Algerian Dinar', value: 'DZD' },
      { option: 'Egyptian Pound', value: 'EGP' },
      { option: 'Eritrean Nakfa', value: 'ERN' },
      { option: 'Ethiopian Birr', value: 'ETB' },
      { option: 'Euro', value: 'EUR' },
      { option: 'Fijian Dollar', value: 'FJD' },
      { option: 'Falkland Islands Pound', value: 'FKP' },
      { option: 'Faroese Króna', value: 'FOK' },
      { option: 'British Pound Sterling', value: 'GBP' },
      { option: 'Georgian Lari', value: 'GEL' },
      { option: 'Guernsey Pound', value: 'GGP' },
      { option: 'Ghanaian Cedi', value: 'GHS' },
      { option: 'Gibraltar Pound', value: 'GIP' },
      { option: 'Gambian Dalasi', value: 'GMD' },
      { option: 'Guinean Franc', value: 'GNF' },
      { option: 'Guatemalan Quetzal', value: 'GTQ' },
      { option: 'Guyanaese Dollar', value: 'GYD' },
      { option: 'Hong Kong Dollar', value: 'HKD' },
      { option: 'Honduran Lempira', value: 'HNL' },
      { option: 'Croatian Kuna', value: 'HRK' },
      { option: 'Haitian Gourde', value: 'HTG' },
      { option: 'Hungarian Forint', value: 'HUF' },
      { option: 'Indonesian Rupiah', value: 'IDR' },
      { option: 'Israeli New Sheqel', value: 'ILS' },
      { option: 'Isle of Man Pound', value: 'IMP' },
      { option: 'Indian Rupee', value: 'INR' },
      { option: 'Iraqi Dinar', value: 'IQD' },
      { option: 'Iranian Rial', value: 'IRR' },
      { option: 'Icelandic Króna', value: 'ISK' },
      { option: 'Jersey Pound', value: 'JEP' },
      { option: 'Jamaican Dollar', value: 'JMD' },
      { option: 'Jordanian Dinar', value: 'JOD' },
      { option: 'Japanese Yen', value: 'JPY' },
      { option: 'Kenyan Shilling', value: 'KES' },
      { option: 'Kyrgystani Som', value: 'KGS' },
      { option: 'Cambodian Riel', value: 'KHR' },
      { option: 'Kiribati Dollar', value: 'KID' },
      { option: 'South Korean Won', value: 'KRW' },
      { option: 'Kuwaiti Dinar', value: 'KWD' },
      { option: 'Cayman Islands Dollar', value: 'KYD' },
      { option: 'Kazakhstani Tenge', value: 'KZT' },
      { option: 'Laotian Kip', value: 'LAK' },
      { option: 'Lebanese Pound', value: 'LBP' },
      { option: 'Sri Lankan Rupee', value: 'LKR' },
      { option: 'Liberian Dollar', value: 'LRD' },
      { option: 'Lesotho Loti', value: 'LSL' },
      { option: 'Libyan Dinar', value: 'LYD' },
      { option: 'Moroccan Dirham', value: 'MAD' },
      { option: 'Moldovan Leu', value: 'MDL' },
      { option: 'Malagasy Ariary', value: 'MGA' },
      { option: 'Macedonian Denar', value: 'MKD' },
      { option: 'Myanmar Kyat', value: 'MMK' },
      { option: 'Mongolian Tugrik', value: 'MNT' },
      { option: 'Macanese Pataca', value: 'MOP' },
      { option: 'Mauritanian Ouguiya (pre-2018)', value: 'MRO' },
      { option: 'Mauritian Rupee', value: 'MUR' },
      { option: 'Maldivian Rufiyaa', value: 'MVR' },
      { option: 'Malawian Kwacha', value: 'MWK' },
      { option: 'Mexican Peso', value: 'MXN' },
      { option: 'Malaysian Ringgit', value: 'MYR' },
      { option: 'Mozambican Metical', value: 'MZN' },
      { option: 'Namibian Dollar', value: 'NAD' },
      { option: 'Nigerian Naira', value: 'NGN' },
      { option: 'Nicaraguan Córdoba', value: 'NIO' },
      { option: 'Norwegian Krone', value: 'NOK' },
      { option: 'Nepalese Rupee', value: 'NPR' },
      { option: 'New Zealand Dollar', value: 'NZD' },
      { option: 'Omani Rial', value: 'OMR' },
      { option: 'Panamanian Balboa', value: 'PAB' },
      { option: 'Peruvian Nuevo Sol', value: 'PEN' },
      { option: 'Papua New Guinean Kina', value: 'PGK' },
      { option: 'Philippine Peso', value: 'PHP' },
      { option: 'Pakistani Rupee', value: 'PKR' },
      { option: 'Polish Złoty', value: 'PLN' },
      { option: 'Paraguayan Guarani', value: 'PYG' },
      { option: 'Qatari Rial', value: 'QAR' },
      { option: 'Romanian Leu', value: 'RON' },
      { option: 'Serbian Dinar', value: 'RSD' },
      { option: 'Russian Ruble', value: 'RUB' },
      { option: 'Rwandan Franc', value: 'RWF' },
      { option: 'Saudi Riyal', value: 'SAR' },
      { option: 'Solomon Islands Dollar', value: 'SBD' },
      { option: 'Seychellois Rupee', value: 'SCR' },
      { option: 'Sudanese Pound', value: 'SDG' },
      { option: 'Swedish Krona', value: 'SEK' },
      { option: 'Singapore Dollar', value: 'SGD' },
      { option: 'Saint Helena Pound', value: 'SHP' },
      { option: 'Sierra Leonean Leone', value: 'SLL' },
      { option: 'Somali Shilling', value: 'SOS' },
      { option: 'Surinamese Dollar', value: 'SRD' },
      { option: 'South Sudanese Pound', value: 'SSP' },
      { option: 'São Tomé and Príncipe Dobra (pre-2018)', value: 'STD' },
      { option: 'Syrian Pound', value: 'SYP' },
      { option: 'Swazi Lilangeni', value: 'SZL' },
      { option: 'Thai Baht', value: 'THB' },
      { option: 'Tajikistani Somoni', value: 'TJS' },
      { option: 'Turkmenistani Manat', value: 'TMT' },
      { option: 'Tunisian Dinar', value: 'TND' },
      { option: 'Tongan Paanga', value: 'TOP' },
      { option: 'Turkish Lira', value: 'TRY' },
      { option: 'Trinidad and Tobago Dollar', value: 'TTD' },
      { option: 'Tuvalu Dollar', value: 'TVD' },
      { option: 'New Taiwan Dollar', value: 'TWD' },
      { option: 'Tanzanian Shilling', value: 'TZS' },
      { option: 'Ukrainian Hryvnia', value: 'UAH' },
      { option: 'Ugandan Shilling', value: 'UGX' },
      { option: 'United States Dollar', value: 'USD' },
      { option: 'Uruguayan Peso', value: 'UYU' },
      { option: 'Uzbekistan Som', value: 'UZS' },
      { option: 'Venezuelan Bolívar', value: 'VES' },
      { option: 'Vietnamese Đồng', value: 'VND' },
      { option: 'Vanuatu Vatu', value: 'VUV' },
      { option: 'Samoan Tala', value: 'WST' },
      { option: 'Central African CFA Franc', value: 'XAF' },
      { option: 'East Caribbean Dollar', value: 'XCD' },
      { option: 'West African CFA Franc', value: 'XOF' },
      { option: 'CFP Franc', value: 'XPF' },
      { option: 'Yemeni Rial', value: 'YER' },
      { option: 'South African Rand', value: 'ZAR' },
      { option: 'Zambian Kwacha', value: 'ZMW' },
      { option: 'Zimbabwean Dollar', value: 'ZWL' },
    ];

    return currencies;
  }

  getCountryMobileCodes() {
    const countryMobileNumberLengths = [
      { code: '+1', option: 'United States', value: 10 },
      { code: '+1', option: 'Canada', value: 10 },
      { code: '+44', option: 'United Kingdom', value: 11 },
      { code: '+61', option: 'Australia', value: 10 },
      { code: '+49', option: 'Germany', value: 11 },
      { code: '+33', option: 'France', value: 10 },
      { code: '+34', option: 'Spain', value: 9 },
      { code: '+39', option: 'Italy', value: 10 },
      { code: '+81', option: 'Japan', value: 11 },
      { code: '+86', option: 'China', value: 11 },
      { code: '+91', option: 'India', value: 10 },
      { code: '+55', option: 'Brazil', value: 11 },
      { code: '+27', option: 'South Africa', value: 10 },
      { code: '+7', option: 'Russia', value: 10 },
      { code: '+52', option: 'Mexico', value: 10 },
      { code: '+64', option: 'New Zealand', value: 10 },
      { code: '+31', option: 'Netherlands', value: 10 },
      { code: '+41', option: 'Switzerland', value: 10 },
      { code: '+46', option: 'Sweden', value: 10 },
      { code: '+47', option: 'Norway', value: 8 },
      { code: '+45', option: 'Denmark', value: 8 },
      { code: '+358', option: 'Finland', value: 10 },
      { code: '+65', option: 'Singapore', value: 8 },
      { code: '+82', option: 'South Korea', value: 11 },
      { code: '+54', option: 'Argentina', value: 10 },
      { code: '+56', option: 'Chile', value: 10 },
      { code: '+20', option: 'Egypt', value: 11 },
      { code: '+49', option: 'Austria', value: 10 },
      { code: '+55', option: 'Portugal', value: 9 },
      { code: '+41', option: 'Belgium', value: 10 },
      { code: '+44', option: 'Ireland', value: 10 },
      { code: '+1', option: 'Puerto Rico', value: 10 },
      { code: '+52', option: 'Colombia', value: 10 },
      { code: '+52', option: 'Guatemala', value: 8 },
      { code: '+503', option: 'El Salvador', value: 8 },
      { code: '+52', option: 'Honduras', value: 8 },
      { code: '+502', option: 'Costa Rica', value: 8 },
      { code: '+506', option: 'Panama', value: 8 },
      { code: '+507', option: 'Venezuela', value: 10 },
      { code: '+58', option: 'Peru', value: 9 },
      { code: '+51', option: 'Ecuador', value: 9 },
      { code: '+593', option: 'Bolivia', value: 8 },
      { code: '+591', option: 'Paraguay', value: 9 },
      { code: '+595', option: 'Uruguay', value: 9 },
      { code: '+598', option: 'Chile', value: 10 },
      { code: '+56', option: 'Haiti', value: 8 },
      { code: '+509', option: 'Jamaica', value: 10 },
      { code: '+1876', option: 'Bahamas', value: 10 },
      { code: '+1242', option: 'Trinidad and Tobago', value: 10 },
    ];
    return countryMobileNumberLengths;
  }
}
