import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.css']
})
export class TabMenuComponent {
  @Input() tabs: { label: string; active: boolean; }[] = [];
  @Output() tabChange: EventEmitter<{ label: string; active: boolean; }> = new EventEmitter();

  selectTab(selectedTab: { label: string; active: boolean; }) {
    this.tabs.forEach(tab => tab.active = tab === selectedTab);
    this.tabChange.emit(selectedTab);
  }
}
