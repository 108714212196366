import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-service.service';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-grouping',
  templateUrl: './grouping.component.html',
  styleUrls: ['./grouping.component.css']
})
export class GroupingComponent {
  constructor(private messageService: MessageService, private globalService: GlobalServicesService, private http: HttpService) { }

  inputItems: any[] = [];
  showEditIcon: boolean[] = [];
  isLoading: boolean = false
  purpose: string = ""
  dataList: any[] = [];

  async ngOnInit() {
    this.inputItems = []
    this.isLoading = true;
    const response = await this.fetchGroupings();
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data;
    }
    this.dataList.forEach(element => {
      element.saved = true;
      element.value = element.grouping_name;
      this.inputItems.push(element)
    });
  }
  addInput() {
    this.purpose = ''
    this.inputItems.push({ value: '', saved: false });
    this.purpose = "create"
    this.showEditIcon.push(false);
  }

  removeInput(index: number) {
    this.inputItems.splice(index, 1);
    this.purpose = '';
  }

  editItem(index: number) {
    this.purpose = ''
    this.inputItems[index].saved = false;
    this.purpose = "edit"
  }

  async saveItem(index: number) {
    if (this.purpose === 'create') {
      this.isLoading = true;
      const invite = await this.groupingImport({
        grouping_name: this.inputItems[index].value
      });
      this.isLoading = false;
      if (invite && invite.success) {
        this.inputItems[index].saved = true;

        this.messageService.add({ severity: 'success', summary: 'Success', detail: invite.data })
        const response = await this.fetchGroupings();
        this.dataList = [];
        this.inputItems = []; 
        if (response.success) {
          this.dataList = response.data;
        }
        this.dataList.forEach(element => {
          element.saved = true;
          element.value = element.grouping_name;
          this.inputItems.push(element)
        });
        this.purpose = '';
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: invite.message })
      }
    }
    else if (this.purpose === 'edit') {
      this.isLoading = true
      const update = await this.groupingUpdate({
        grouping_id: this.inputItems[index].grouping_id,
        grouping_name: this.inputItems[index].value
      });
      this.isLoading = false
      if (update && update.success) {
        this.inputItems[index].saved = true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: update.data })
        const response = await this.fetchGroupings();
        this.dataList = [];
        this.inputItems = []; 
        if (response.success) {
          this.dataList = response.data;
        }
        this.dataList.forEach(element => {
          element.saved = true;
          element.value = element.grouping_name;
          this.inputItems.push(element)
        });
        this.purpose = ''
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: update.message })
      }
    }
    this.isLoading = false
  };

  async groupingImport(body: any) {
    const fetchProgrammeDetails = await this.http.post('/groupings/import', body);
    return fetchProgrammeDetails;
  };

  async fetchGroupings() {
    const fetchProgrammeDetails = await this.http.get('/groupings/fetch');
    return fetchProgrammeDetails;
  }

  async groupingUpdate(body: any) {
    const fetchProgrammeDetails = await this.http.post('/groupings/update', body);
    return fetchProgrammeDetails;
  }

  async deleteGroupings(body: any) {
    const fetchProgrammeDetails = await this.http.post('/groupings/delete', body);
    return fetchProgrammeDetails;
  }

  async deleteTemplate(grouping: any, index: any) {
    this.inputItems.splice(index, 1);
    const payload: any = {}
    this.isLoading = true;
    payload.grouping_id = grouping.grouping_id
    await this.deleteGroupings(payload);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' })
    const response = await this.fetchGroupings();
    if (response.success) {
      this.dataList = response.data;
    }
    this.isLoading = false;
  }

}
