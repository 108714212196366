<div class="table-relative">
    <p-sidebar [(visible)]="showFilter" [transitionOptions]="'0.3ms'"
        [style]="{padding: '0', border: '2px solid white'}" [modal]="false" [baseZIndex]="0" position="left"
        ariaCloseLabel="Filter" (onHide)="cancelFilterOptions()">
        <ng-template pTemplate="header">
            <h3>{{tableEnum.FILTERHEAD}}</h3>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="filter-body">
                <p-accordion [activeIndex]="[0]" [multiple]="true">
                    <p-accordionTab *ngFor="let section of filterData | keyvalue" header={{section.key}}>
                        <div class="filter-names" *ngFor="let options of section.value">
                            <p-checkbox [(ngModel)]="options.selected"
                                (onChange)="checkFilterOptions($event,section.key , options , filterData)"
                                [binary]="true" inputId="binary"></p-checkbox>
                            <span>{{options.label?options.label:options.name}}</span>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="filter-save" [style]="{'gap': '15px','height': '90px' , 'margin-left': '0'}">
                <div class="filter-text">
                    <span>{{tableEnum.SAVEFILTERS}}</span>
                </div>
                <div class="filter-btn">
                    <button pButton pRipple label="Primary" class="p-button-filter blue-outlined-btn"
                        (click)="cancelFilterOptions()">{{globalEnum.BTNCANCEL}}</button>
                    <button pButton pRipple label="createnew" class="p-button-filter blue-filled-btn"
                        (click)="filtersApply()">{{globalEnum.BTNAPPLY}}
                    </button>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
    <p-sidebar [(visible)]="showList" [transitionOptions]="'0.3ms'" [style]="{padding: '0', border: '2px solid white', height: tableheight}"
        [modal]="false" [baseZIndex]="0" position="left" ariaCloseLabel="Filter">
        <ng-template pTemplate="header">
            <h3>{{leftSidebarName}}</h3>
        </ng-template>
        <ng-template pTemplate="body">
            <div class="column-ctn">
                <div class="download-columns-select-all">
                    <p-checkbox *ngIf="leftSidebarName === 'Download Columns'" [(ngModel)]="downloadColumnSelectAll"
                    (onChange)="downloadColumnAll($event)" [binary]="true" inputId="binary"></p-checkbox>
                    <span *ngIf="leftSidebarName === 'Download Columns'">Select All</span>  
                </div>
                <ng-container  *ngFor="let col of columns">
                    <div class="column-list" *ngIf="col.header">
                        <div class="column-name">
                            <p-checkbox *ngIf="leftSidebarName === 'Download Columns'" [(ngModel)]="col.selected"
                                (onChange)="checkColumnName(col)" [binary]="true" inputId="binary"></p-checkbox>
                            <p-checkbox *ngIf="leftSidebarName === 'Column List'" [(ngModel)]="col.tableDefault" [binary]="true"
                                inputId="binary" [disabled]="col.freezable"></p-checkbox>
                            <span>{{col.header}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="btn-cnt" *ngIf="leftSidebarName != 'Column List'">
                <div class="filter-save"
                    [style]="leftSidebarName === 'Column List' ? {'gap': '15px','height': '90px'} :  {} ">
                    <div class="filter-text">
                        <span>{{ leftSidebarName === 'Column List' ? 'Save Selected Filters' :
                            'Download as' }}</span>
                    </div>
                    <ng-container
                        *ngIf="leftSidebarName === 'Column List'; then columnList else downloadColumns"></ng-container>
                    <ng-template #columnList>
                        <div class="filter-btn">
                            <button pButton pRipple label="Primary"
                                class="p-button-filter blue-outlined-btn">Cancel</button>
                            <button pButton pRipple label="createnew" class="p-button-filter blue-filled-btn">Apply
                            </button>
                        </div>
                    </ng-template>
                    <ng-template #downloadColumns>
                        <div class="radioButton-group">
                            <div class="radioButton">
                                <p-radioButton value="excel" inputId="excel" [(ngModel)]="downloadColumnAs">
                                </p-radioButton>
                                <label for="excel"
                                    [style]="{color: downloadColumnAs === 'excel' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                        class="pi pi-file"></em>Excel</label>
                            </div>
                            <div class="radioButton">
                                <p-radioButton value="csv" inputId="csv" [(ngModel)]="downloadColumnAs">
                                </p-radioButton>
                                <label for="csv"
                                    [style]="{color: downloadColumnAs === 'csv' ? 'var(--clr-primary)' : 'var(--fc-light-dark)'}"><em
                                        class="pi pi-file-excel"></em>CSV</label>
                            </div>
                        </div>
                        <div style="margin-left: 5%;">
                            <button style="width: 100%;" pButton pRipple
                                class="p-button-filter blue-filled-btn"
                                (click)="downloadColumnsAs()">Download
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </p-sidebar>
    <p-table [columns]="columns" [value]="dataList" scrollHeight="{{tableheight}}" scrollDirection="both"
        [resizableColumns]="false" [rows]="5" [scrollable]="true" selectionMode="single"
        [(selection)]="selectedProduct">
        <ng-template pTemplate="caption">
            <div class="caption-cont">
                <div class="left-icons">
                    <span *ngIf="action"><i class="pi pi-ellipsis-v  " (click)="overlayPanel.toggle($event)"></i>
                        <p-overlayPanel class="no-arrow-panel" #overlayPanel [dismissable]="true" appendTo="body"
                            [style]="{ width: '120px' }">
                            <div *ngFor="let options of actionOptions">
                                <span class="action-option" (click)="onClickAction(options)">{{options.label}}</span>
                            </div>
                        </p-overlayPanel>
                    </span>
                    <span *ngIf="columnList"><i class="pi pi-sliders-h" (click)="openlist('Column List')"
                            [ngClass]="{'active-icon': leftSidebarName === 'Column List' && showList}"></i></span>
                    <span *ngIf="tableFilter"><i class="pi pi-filter" (click)="openFilter()"
                            [ngClass]="{'active-icon': showFilter}"></i></span>
                    <span *ngIf="download"><i class="pi pi-download" (click)="openDownload('Download Columns')"
                            [ngClass]="{'active-icon': leftSidebarName === 'Download Columns'&& showList}"></i></span>
                    <div class="search-bar">
                        <em class="pi pi-search" style="font-size: 13px;cursor: pointer"></em>
                        <input #inputRef [(ngModel)]="searchValue" class="input-field" type="text"
                            placeholder="Search..." (keyup.enter)="inputEnter($event)"
                            (change)="searchValueChange($event)"
                            [style.color]="searchColor ? 'var(--clr-seconadary)' : 'var(--clr-black)'"
                            (input)="onSearchChange($event)">
                    </div>
                    <p-divider layout="vertical"></p-divider>
                    <div class="totallist-text whiteSpaceNoWarp">{{tableEnum.TOTALLIST}} : {{dataCount}}</div>
                    <p-divider *ngIf="selectedOptions.length" layout="vertical"></p-divider>
                    <div *ngIf="selectedOptions.length && isRowsSelectedDisplay" class="totallist-text whiteSpaceNoWarp total-selected-size">{{tableEnum.SCOUNT}} :
                        {{selectedOptions.length}}
                    </div>
                </div>
                <div class="right-cont" *ngIf="pageAndRow">
                    <p [style]="{paddingRight: '5px'}">{{globalEnum.VIEW}}</p>
                    <p-dropdown [options]="pageLimits" [(ngModel)]="pageSize" (onChange)="rowLimit($event)"
                        [style]="{ maxWidth: '6rem',maxHeight: '38px'}"></p-dropdown>
                    <p-divider layout="vertical"></p-divider>
                    <button class="disabled p-button-secondary p-button-text simple-btn whiteSpaceNoWarp">
                        {{ currentPage }} to {{ totalPage }}
                    </button>
                    <button class="p-button-text p-button-secondary simple-btn"
                        (click)="previousPagination($event)"><i class="pi pi-angle-left"></i></button>
                    <button class="p-button-text p-button-secondary simple-btn"
                        (click)="nextPagination($event)"><i class="pi pi-angle-right"></i></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr id="targetDiv" class="table-header" [ngClass]="{'moveTable': showFilter || showList}">
                <th *ngIf="checkBox" [style]="{width: '4vw'}" scope="col" pFrozenColumn>
                    <p-checkbox [(ngModel)]="checkedAll" (onChange)="checkAll($event)" [binary]="true"
                        inputId="binary"></p-checkbox>
                </th>
                <ng-container *ngFor="let col of columns">
                    <ng-container *ngIf="col.freezable; then freezable; else nonfreezable">
                    </ng-container>
                    <ng-template #freezable>
                        <th *ngIf="col.tableDefault" class="hide-hover" [style.width]="col.width" pResizableColumn
                            [pSortableColumn]="col.sortable ? col.field : null" pFrozenColumn
                            (mouseenter)="onMouseEnter(col.field)" (mouseleave)="onMouseLeave(col.field)">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable && sortIconVisible[col.field]"
                                [field]="col.field"></p-sortIcon>
                        </th>
                    </ng-template>
                    <ng-template #nonfreezable>
                        <th *ngIf="col.tableDefault" class="hide-hover" [style.width]="col.width" pResizableColumn
                            [pSortableColumn]="col.sortable ? col.field : null" (mouseenter)="onMouseEnter(col.field)"
                            (mouseleave)="onMouseLeave(col.field)">
                            {{ col.header }}
                            <p-sortIcon *ngIf="col.sortable && sortIconVisible[col.field]"
                                [field]="col.field"></p-sortIcon>
                        </th>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <ng-container *ngIf="dataList.length">
                <tr class="table-body" style="border-radius: 5px;" [ngClass]="{'moveTable': showFilter || showList}">
                    <td *ngIf="checkBox" [style]="{width: '4vw'}" pFrozenColumn>
                        <p-checkbox (onChange)="checkedrow(rowData)" [(ngModel)]="rowData.selected" [binary]="true"
                            inputId="binary"></p-checkbox>
                    </td>
                    <!-- <td [style]="{width: '8vw'}" pFrozenColumn> -->
                    <!-- <p-checkbox (onChange)="checkedrow(rowData)" [(ngModel)]="rowData.selected" [binary]="true"
                        inputId="binary"></p-checkbox> -->
                    <!-- <button class="redirect-button pi pi-arrow-up-right" pButton type="button" (click)="handleButtonClick(rowData)"></button>
                    </td> -->
                    <ng-container *ngFor="let col of columns">
                        <ng-container *ngIf="col.freezable; then freezableRow; else nonfreezableRow">
                        </ng-container>
                        <ng-template #freezableRow>
                            <td class="truncate-cell" *ngIf="col.tableDefault && col.field !== 'redirect' && !col.img"
                                 [style.width]="col.width" pFrozenColumn pResizableColumn
                                (click)="onSelectedEventChange(rowData)">
                                <span [pTooltip]="rowData[col.field]?.length > 20 ? rowData[col.field] : null"
                                    [tooltipPosition]="'top'">
                                    {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                </span>
                            </td>
                            <td class="truncate-cell" *ngIf="col.tableDefault && col.field === 'redirect' && !col.img"
                                 [style.width]="col.width" pFrozenColumn pResizableColumn>
                                <i style="font-size: 18px; margin-left: 20px;" class="pi pi-external-link"
                                    (click)="handleButtonClick(rowData)"></i>

                                <!-- <button class="redirect-button" pButton type="button"
                                    (click)="handleButtonClick(rowData)"><i class="pi pi-external-link"></i></button> -->
                            </td>
                            <td class="image-cell" *ngIf="col.tableDefault && col.img"  [style.width]="col.width"
                                pFrozenColumn pResizableColumn>
                                <img [style]="{width: '4vh' , height: '4vh'}"
                                    [src]="rowData[col.field] ? rowData[col.field] : '-'" alt="Image" />
                            </td>
                        </ng-template>
                        <ng-template #nonfreezableRow>
                            <td (click)="rowClick(rowData)" class="truncate-cell"
                                *ngIf="col.tableDefault && !(col.field === 'FieldTypes')"  [style.width]="col.width"
                                pResizableColumn (click)="onSelectedEventChange(rowData)">
                                <span [pTooltip]="rowData[col.field]?.length > 20 ? rowData[col.field] : null"
                                    [tooltipPosition]="'top'">
                                    {{ rowData[col.field] ? rowData[col.field] : "-" }}
                                </span>
                            </td>
                            <td (click)="rowClick(rowData)" *ngIf="col.field === 'FieldTypes'"
                                [style]="{width: 'auto', display: 'flex', gap:'10px', padding:'0.5rem'}"
                                pResizableColumn>
                                <ng-container *ngFor="let data of rowData[col.field]">
                                    <p-chip [label]="data.field_type"></p-chip>
                                </ng-container>
                            </td>
                        </ng-template>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr class="no-record-display">
                <td [style]="{border:'unset'}">No Records Found</td>
            </tr>
        </ng-template>
    </p-table>
</div>