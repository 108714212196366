import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HttpService } from './http.service';
import { ApiResponse } from '../interfaces/api-response';


@Injectable({
    providedIn: 'root',
})
export class SuperAdminService {
    api_url = environment.HOST_LINK;
    private authorizeToken: any = ``;
    constructor(private HttpClient: HttpClient, private router: Router, private httpService: HttpService) {
    }
    public async validUser(payload: any): Promise<ApiResponse> {
            const data = await this.httpService.post('/signIn', payload)
            return data
    }

    public getApiCall(endpoint: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const tokenDetails: any = localStorage.getItem('token');
            this.authorizeToken = tokenDetails ? JSON.parse(tokenDetails) : '';
            const headers: any = new HttpHeaders({ Authorization: this.authorizeToken });
            const header = { headers: headers };
            this.HttpClient.get(this.api_url + endpoint, header).subscribe((response: any) => {
                resolve(response);
            },
                (err: any) => {
                    console.error('schooldetails err');
                    resolve(false);
                });
        });
    }

    public postApiCall(endpoint: any, body: any): Promise<any> {
        console.log('endpoint', endpoint)
        return new Promise((resolve, reject) => {
            // const tokenDetails: any = localStorage.getItem('token');
            // this.authorizeToken = tokenDetails ? JSON.parse(tokenDetails) : '';
            // const headers: any = new HttpHeaders({ Authorization: this.authorizeToken });
            // const header = { headers: headers };
            this.HttpClient.post(this.api_url + endpoint, body).subscribe((response: any) => { resolve(response) },
                (err: any) => {
                    console.error('schooldetails err');
                    resolve(false);
                });
        });
    }
}
