import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-service.service';
import { HttpService } from '../services/http.service';
import { AttributesService } from '../services/attributes.service';

@Component({
  selector: 'app-attribute-creation',
  templateUrl: './attribute-creation.component.html',
  styleUrls: ['./attribute-creation.component.css'],
})
export class AttributeCreationComponent implements OnInit {
  @ViewChild('overlayPanel') overlaypanel: any;
  isLoading = false;
  sideBarOperation = '';
  sideBarButton = '';
  searchValue: any = '';
  columns: any[] = [];
  pageAndRow: boolean = true
  dataList: any[] = [];
  rowsize: number = 10;
  dataCount: number = 0;
  totalPage: number = 0;
  selectedPage: any = 1;
  searchColor: boolean = true;
  createSidebarShow: boolean = false;
  pageSize: number = 10;
  pageLimits: any[] = [];
  showFilter: boolean = false;
  showList: boolean = false;
  showDownload: boolean = false;
  inputData: any[] = [];
  checkedAll: boolean = false;
  selectedOptions: any[] = [];
  leftSidebarName: string = '';
  createNewField: FormGroup = new FormGroup({});
  formValid: boolean = false;
  indexnumber: number = 1;
  sortIconVisible: { [key: string]: boolean } = {};
  selectedFilterOptions: any = {};
  checkedColumns: any[] = [];
  downloadColumnAs: string = 'excel';
  showDropdown: boolean = false;
  actionOverlayPanel: boolean = false;
  actionOptions: any[] = [];
  selectedAction: any;
  openEditSidebar: boolean = false;
  patchedValues: any = {};
  showBulkUpload: boolean = false;
  attribute_creation = new FormGroup({
    attribute_name: new FormControl('', Validators.required),
    attribute_description: new FormControl(''),
    field: new FormControl('', Validators.required),
    placeholder: new FormControl(''),
    sample_data: new FormControl(''),
    isSpecialCharacter: new FormControl<boolean>(false),
    field_options: new FormControl<string[] | null>(null),
    min_length: new FormControl<number | null>(null),
    max_length: new FormControl<number | null>(null),
    currency_type: new FormControl(Validators),
    min_number: new FormControl<number | null>(null),
    max_number: new FormControl<number | null>(null),
    max_fraction: new FormControl<number | null>(null),
    mobile_no: new FormControl<number | null>(null),
    min_date: new FormControl<Date | null>(null),
    max_date: new FormControl<Date | null>(null),
    grouping: new FormControl<boolean | null>(false),
    max_file_size: new FormControl<number | null>(null),
    file_types: new FormControl<string[] | null>(null),
  });

  field_types: Array<object> = [];
  fileTypesArray = this.arrayToOptionValueArray([
    'csv',
    'pdf',
    'jpeg',
    'jpg',
    'xlsx',
    'png',
    'docx',
    'ppt',
    'txt',
  ]);
  modeArray: Array<string> = [];
  currencyType = this.globalService.getCurrencyCodes();
  inputNumber = new FormControl<string | null>(null);
  minLabel = '';
  maxLabel = '';
  fields: any = [];
  mode = '';
  currency = '';
  mobileNoCodes = this.globalService.getCountryMobileCodes();

  arrayToOptionValueArray(arr: Array<string>) {
    return arr.map((item) => ({
      option: item.toString(),
      value: item,
    }));
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private httpService: HttpService,
    private attributeService: AttributesService,
    private confirmationService: ConfirmationService
  ) {
    this.pageLimits = [
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];
    this.columns = [
      {
        field: 'attribute_name',
        header: 'Attribute Name',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'field',
        header: 'Field Type',
        sortable: true,
        freezable: false,
        tableDefault: true,
      },
      {
        field: 'placeholder',
        header: 'Placeholder',
        sortable: true,
        freezable: false,
        tableDefault: true,
      }
    ];

    this.actionOptions = [
      { label: 'Edit', value: 'edit' },
    ];
    this.searchValue = '';
  }

  selectedCurrencyType(data: any) {
    const currency_code = data.value;
    this.currency = currency_code;
    if (currency_code) {
      this.minLabel = `Minimum ${currency_code}`;
      this.maxLabel = `Maximum ${currency_code}`;
    }
  }

  disableField(field: string) {
    this.attribute_creation.get(field)?.setValue(null);
    this.attribute_creation.get(field)?.disable();
  }

  disableFields(field: string[]) {
    _.forEach(field, (eachField) => {
      this.attribute_creation.get(eachField)?.setValue(null);
      this.attribute_creation.get(eachField)?.disable();
    });
  }

  enableField(field: string) {
    this.attribute_creation.get(field)?.enable();
  }

  enableFields(field: string[]) {
    console.log('Enable field', field);
    _.forEach(field, (eachField) => {
      this.attribute_creation.get(eachField)?.enable();
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.createNewField = this.createFormGroupFromInputData();
    this.httpService.get('/fields').then((data) => {
      this.fields = data.data;
    });
    this.fetchAttributes();

    this.attribute_creation.controls.field.valueChanges.subscribe((data) =>
      console.log(data)
    );
  }

  createFormGroupFromInputData() {
    const formGroupConfig: any = {};
    this.inputData.forEach((data) => {
      formGroupConfig[data.controlfield] = new FormControl('');
    });
    return new FormGroup(formGroupConfig);
  }

  bulkUpload() {
    this.showBulkUpload = true;
  }

  createNew() {
    this.createNewField = this.createFormGroupFromInputData();
    this.createSidebarShow = !this.createSidebarShow;
    this.sideBarOperation = 'Create Attribute';
    this.sideBarButton = 'create';
    this.attribute_creation.reset();
  }

  fetchAttributes() {
    this.isLoading = true;
    const body = { limit: this.rowsize, page: this.selectedPage };
    this.attributeService.fetchAttributes(body).then((response) => {
      console.log('data', response.data.data)
      if (response.success) {
        this.dataList = response.data.data;
        this.dataCount = response.data.count;
        this.totalPage = Math.ceil(this.dataCount / this.rowsize);
        this.isLoading = false;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Network Error',
          detail: 'Check your network connection!',
        });
        this.isLoading = false;
      }
    });
  }

  clearFilter(event: any) {
    console.log('Event', event);
  }

  onSubmitCreate() {
    console.log('Attribute creation', this.attribute_creation.value);
    this.formValid = this.createNewField.valid;
    const field_name: any = this.attribute_creation.controls['field'].value;
    const payload = {
      attribute_name: this.attribute_creation.controls['attribute_name'].value,
      attribute_description:
        this.attribute_creation.controls['attribute_description'].value,
      field: this.attribute_creation.controls['field'].value,
      placeholder: this.attribute_creation.controls['placeholder'].value,
      sample_data: this.attribute_creation.controls['sample_data'].value,
      validations: {
        isSpecialCharacter:
          this.attribute_creation.controls['isSpecialCharacter'].value,
        field_options: this.attribute_creation.controls['field_options'].value,
        currency_type: this.attribute_creation.controls['currency_type'].value,
        min_length: this.attribute_creation.controls['min_length'].value,
        max_length: this.attribute_creation.controls['max_length'].value,
        min_number: this.attribute_creation.controls['min_number'].value,
        max_number: this.attribute_creation.controls['max_number'].value,
        max_fraction: this.attribute_creation.controls['max_fraction'].value,
        mobile_no: this.attribute_creation.controls['mobile_no'].value,
        max_file_size: this.attribute_creation.controls['max_file_size'].value,
        file_types: this.attribute_creation.controls['file_types'].value,
        min_date: this.attribute_creation.controls['min_date'].value,
        max_date: this.attribute_creation.controls['max_date'].value,
      },
    };

    console.log('Payload', payload);
    if (!this.attribute_creation.valid) {
      console.log(this.attribute_creation.valid);
      this.messageService.add({
        severity: 'error',
        summary: 'Attribute Creation',
        detail: 'Enter all required fields!',
      });
    } else {
      this.isLoading = true;
      this.attributeService.createAttributes(payload).then((data) => {
        if (data.success) {
          console.log('Success', data);
          this.messageService.add({
            severity: 'success',
            summary: 'Attribute Creation',
            detail: 'Successfully Created!',
          });
          this.createSidebarShow = false;
          this.fetchAttributes();
          this.isLoading = false;
        } else {
          console.log('error', data);
          this.messageService.add({
            severity: 'error',
            summary: 'Attribute Creation',
            detail: 'Failed Creating!',
          });
          this.isLoading = false;
        }
      });
    }
  }

  transformAttribute(attribute: any) {
    console.log('attribute', attribute)
    const { validation, ...rest } = attribute;
    return { ...rest, ...validation };
  }

  openEditFiled(event: any) {
    this.sideBarOperation = 'Update Attribute';
    this.sideBarButton = 'update';
    this.selectedOptions = event;
    if (this.selectedOptions.length === 0 || this.selectedOptions.length > 2) {
      this.messageService.add({
        severity: 'error',
        summary: 'Validate',
        detail: 'Select only one row',
      });
      return;
    }
    this.createNewField.patchValue(this.selectedOptions);
    console.log('this.createNewField', this.createNewField)
    this.patchedValues = _.cloneDeep(this.createNewField);
    this.createSidebarShow = true;
    console.log('this.selectedOptions', this.selectedOptions)
    console.log('this.attribute_creation', this.attribute_creation)
    this.attribute_creation.patchValue(
      this.transformAttribute(this.selectedOptions[0])
    );
  }

  onEditFields() {
    let changedFields: any = {};
    for (const controlField in this.createNewField.controls) {
      if (
        this.patchedValues.controls[controlField].value !==
        this.createNewField.controls[controlField].value
      ) {
        changedFields[controlField] =
          this.patchedValues.controls[controlField].value;
      }
    }
    this.createSidebarShow = !this.createSidebarShow;
    this.createNewField = this.createFormGroupFromInputData();
    this.patchedValues = _.cloneDeep(this.createNewField);
    const payload = {
      attribute_id: this.selectedOptions[0].attribute_id,
      attribute_name: this.attribute_creation.controls['attribute_name'].value,
      attribute_description:
        this.attribute_creation.controls['attribute_description'].value,
      field: this.attribute_creation.controls['field'].value,
      placeholder: this.attribute_creation.controls['placeholder'].value,
      sample_data: this.attribute_creation.controls['sample_data'].value,
      validations: {
        field_options: this.attribute_creation.controls['field_options'].value,
        currency_type: this.attribute_creation.controls['currency_type'].value,
        min_length: this.attribute_creation.controls['min_length'].value,
        max_length: this.attribute_creation.controls['max_length'].value,
        grouping: this.attribute_creation.controls['grouping'].value,
        min_number: this.attribute_creation.controls['min_number'].value,
        max_number: this.attribute_creation.controls['max_number'].value,
        max_fraction: this.attribute_creation.controls['max_fraction'].value,
        mobile_no: this.attribute_creation.controls['mobile_no'].value,
        max_file_size: this.attribute_creation.controls['max_file_size'].value,
        file_types: this.attribute_creation.controls['file_types'].value,
        min_date: this.attribute_creation.controls['min_date'].value,
        max_date: this.attribute_creation.controls['max_date'].value,
      },
    };
    if (!this.attribute_creation.valid) {
      console.log(this.attribute_creation.valid);
      this.messageService.add({
        severity: 'error',
        summary: 'Attribute Updation',
        detail: 'Enter all required fields!',
      });
    } else {
      this.isLoading = true;
      this.attributeService.updateAttributes(payload).then((data) => {
        if (data.success) {
          console.log('Success', data);
          this.messageService.add({
            severity: 'success',
            summary: 'Attribute Creation',
            detail: 'Successfully Updated!',
          });
          this.createSidebarShow = false;
          this.fetchAttributes();
          this.isLoading = false;
        } else {
          console.log('error', data);
          this.messageService.add({
            severity: 'error',
            summary: 'Attribute Updation',
            detail: 'Failed Updating!',
          });
          this.isLoading = false;
        }
      });
    }
  }
  deleteData(event: any) {
    if (event.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Attribute Deletion',
        detail: 'Please select any items',
      });
    } else {
      const selectedAttributesId: Array<string> = [];
      const filterId = _.forEach(event, (data: any) => {
        selectedAttributesId.push(data.attribute_id);
      });
      const uniqSelectedIDs = _.uniq(selectedAttributesId);
      const payload = { attribute_ids: uniqSelectedIDs };
      this.confirmationService.confirm({
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.isLoading = true;
          this.attributeService.deleteAttributes(payload).then((data) => {
            if (data.success) {
              this.messageService.add({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Record deleted',
              });
              this.fetchAttributes();
              this.isLoading = false;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Attribute Deletion',
                detail: 'An error occured try again!',
              });
              this.isLoading = false;
            }
          });
        },
        reject: (type: any) => {
          console.log('Type', type);
          if (type == 2) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have Cancelled',
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have Rejected',
            });
          }
        },
      });
    }
  }

  showHistory() {}

  saveBulkUpload() {}

  async searchValueChange(searchValue: any) {
    this.searchValue = searchValue;
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    this.isLoading = true;
    this.attributeService.fetchAttributes(body).then((response) => {
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  })
    return
  }

  async rowLengthChange(length: any) {
    this.rowsize = length;
    const body = { "limit": this.rowsize, "page": this.selectedPage, search: this.searchValue };
    this.isLoading = true;
    this.attributeService.fetchAttributes(body).then((response) => {
      this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  })
    return
  }

  async pageChange(selectedPage: any) {
    this.selectedPage = selectedPage;
    const body = {
      limit: this.rowsize,
      page: this.selectedPage,
      search: this.searchValue,
    };
    this.isLoading = true;
    this.attributeService.fetchAttributes(body).then((response) => {
      this.isLoading = false;
    if (response.success) {
      this.dataList = response.data.data;
      this.dataCount = response.data.count;
      this.totalPage = Math.ceil(this.dataCount / this.rowsize);
    }
  })
    return;
  }
}
