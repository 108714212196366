<div *ngIf="isLoading" class="{{ isLoading ? 'loadingOverlay' : '' }}">
  <p-progressSpinner
    [style]="{ height: '30px', width: '30px' }"
    strokeWidth="7"
    animationDuration="0.5s"
  >
  </p-progressSpinner>
</div>
<div class="input-container">
  <button
    (click)="addInput()"
    class="p-button-create blue-filled-btn"
    *ngIf="purpose != 'edit'"
    pButton
    label="Create New"
  ></button>
</div>

<div class="tab_bg">
<div
  *ngFor="let inputItem of inputItems; let i = index"
  class="input-item cnt"
  (mouseenter)="showEditIcon[i] = true"
  (mouseleave)="showEditIcon[i] = false"
>
  <ng-container *ngIf="!inputItem.saved; else savedValue">
    <div class="flex" style="gap:15px">
      <input style="margin: 0 !important;"
        type="text"
        pInputText
        [(ngModel)]="inputItem.value"
        placeholder="Enter Job Types"
      />
      <i *ngIf="inputItem.value" class="pi pi-check" (click)="saveItem(i)"></i>
      <i
        *ngIf="!inputItem.value"
        class="pi pi-times"
        (click)="removeInput(i)"
      ></i>
    </div>
    <!-- Make the pi-pencil and pi-trash icons visible when showEditIcon[i] is true -->
    <!-- <i *ngIf="showEditIcon[i]" class="pi pi-pencil" (click)="editItem(i)"></i>
    <i *ngIf="showEditIcon[i]" class="pi pi-trash" (click)="deleteTemplate(inputItem, i)"></i> -->
  </ng-container>

  <ng-template #savedValue>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <div class="flex" style="align-self: center; font-weight: 600">
        {{ inputItem.value }}
      </div>
      <div class="flex" style="gap: 10px">
        <i
          *ngIf="showEditIcon[i] && purpose != 'create'"
          class="pi pi-pencil icon-edit"
          (click)="editItem(i)"
        ></i>
        <i
          *ngIf="showEditIcon[i] && purpose != 'create'"
          class="pi pi-trash icon-trash"
          (click)="deleteTemplate(inputItem, i)"
        ></i>
      </div>
    </div>
  </ng-template>
  <p-toast></p-toast>
</div>
</div>
