<div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>

<div class="header-cont">
    <span>{{selectedGroupAccountId && selectedGroupAccountDomain ? 'Edit Field' : 'Create new'}}</span>
    <div class="header-button">
        <button pbutton *ngIf="((createNewField.valid && !editInput )|| !createNewField.valid)"
            class="p-button-create blue-filled-btn"
            (click)="selectedGroupAccountId && selectedGroupAccountDomain ? onEditFields() : onSubmitCreate()"
            type="submit">{{selectedGroupAccountId && selectedGroupAccountDomain ?
            'Update' : 'Save & Next'}}</button>
        <button label="Primary" *ngIf="editInput" class="p-button-edit blue-outlined-btn pi pi-pencil"
            (click)="editAccount()"></button>
    </div>
</div>
<p-divider layout="horizontal"></p-divider>
<form class="createform" [formGroup]="createNewField" type="submit" (ngSubmit)="onSubmitCreate()" id="create-student">
    <div class="flex-container">
        <ng-container *ngFor="let row of inputData; let i = index">
            <div class="form-column" *ngIf="i % 2 === 0">
                <span class="p-float-label create-fields" *ngFor="let innerRow of inputData.slice(i, i + 2)">
                    <ng-container *ngIf="innerRow.fieldtype === 'input'">
                        <input formControlName="{{innerRow.controlfield}}" type="{{innerRow.type}}" pInputText
                            [readonly]="innerRow.isDisable"
                            [ngStyle]="{'cursor': !innerRow.isDisable ? 'auto' : 'not-allowed'}">
                        <label for="inputtext">{{innerRow.label}}<span class="imp"
                                *ngIf="innerRow.mandatory">*</span></label>
                    </ng-container>
                    <ng-container *ngIf="innerRow.fieldtype === 'dropdown'">
                        <p-dropdown formControlName="{{innerRow.controlfield}}" [options]="innerRow.array"
                            [autoDisplayFirst]="false" [readonly]="innerRow.isDisable">
                        </p-dropdown>
                        <label for="dropdown">{{innerRow.label}}<span class="imp"
                                *ngIf="innerRow.mandatory">*</span></label>
                    </ng-container>
                </span>
            </div>
        </ng-container>
    </div>
</form>
<p type="button" *ngIf="editInput && showAddCampus" class="add-campus" (click)="addCampus()"><span
        style="cursor: pointer;">+ Add Account</span></p>
<p type="button" *ngIf="editInput && !showAddCampus" class="add-campus" (click)="createCampus()"><span
            style="cursor: pointer;">+ Create Account</span></p>
<p-tabView id="closableTabView" (onClose)="handleChange($event)" [(activeIndex)]="activeIndex">
    <p-tabPanel *ngFor="let campus of accountsArray ; trackBy: trackByFn; let idx = index" [header]="campus.header"
        [closable]="campus.closable">
        <ng-container *ngIf="accountsData.length > 0">
            <form [formGroup]="accountsData[idx]" (ngSubmit)="onSubmitCreate()" id="create-student">
                <!-- Your form controls here -->
                <div class="flex-container">
                    <ng-container *ngFor="let row of campusDetails; let i = index">
                        <ng-container *ngIf="!row.logo">
                            <div class="form-column" *ngIf="i % 2 === 0">
                                <span class="p-float-label create-fields"
                                    *ngFor="let innerRow of campusDetails.slice(i, i + 2)">
                                    <ng-container *ngIf="innerRow.fieldtype === 'input' && !innerRow.logo">
                                        <input formControlName="{{innerRow.controlfield}}" type="{{innerRow.type}}"
                                            pInputText [readonly]="innerRow.isDisable && campus.isDisable"
                                            [ngStyle]="{'cursor': !innerRow.isDisable || (innerRow.isDisable && !campus.isDisable) ? 'auto' : 'not-allowed'}">
                                        <label for="inputtext">{{innerRow.label}}<span class="imp"
                                                *ngIf="innerRow.mandatory">*</span></label>
                                    </ng-container>
                                    <ng-container *ngIf="innerRow.fieldtype === 'dropdown' && !innerRow.logo">
                                        <p-dropdown formControlName="{{innerRow.controlfield}}"
                                            [options]="innerRow.array" [autoDisplayFirst]="false"
                                            [readonly]="campus.isDisable"
                                            [ngStyle]="{'cursor': !innerRow.isDisable || (innerRow.isDisable && !campus.isDisable) ? 'auto' : 'not-allowed'}">
                                        </p-dropdown>
                                        <label for="dropdown">
                                            {{innerRow.label}}<span class="imp"
                                                *ngIf="innerRow.mandatory">*</span></label>
                                    </ng-container>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </form>
        </ng-container>
    </p-tabPanel>
</p-tabView>