import { environment } from 'src/app/environments/environment';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../services/http.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group-creation',
  templateUrl: './group-creation.component.html',
  styleUrls: ['./group-creation.component.css']
})

export class GroupCreationComponent implements OnInit {

  dataList: any[] = [];
  dataCount: number = 0;
  actionOptions: any[] = [];
  totalPage: number = 0;
  isLoading: boolean = false;
  pageLimits: { label: string, value: number }[] = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  columns: any[] = [
    {
      field: 'redirect',
      header: 'Redirect URL',
      sortable: false,
      freezable: true,
      tableDefault: true,
      width: '18vw'
    },
    {
      field: 'school_name',
      header: 'College Name',
      sortable: true,
      freezable: false,
      tableDefault: true,
      width: '18vw'
    },
    {
      field: 'domain_name',
      header: 'Subdomain Name',
      sortable: true,
      freezable: false,
      tableDefault: true,
      width: '18vw'
    },
    {
      field: 'primary_email',
      header: 'Email Address',
      sortable: true,
      freezable: false,
      tableDefault: true,
      width: '18vw'
    }
  ]

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private router: Router
  ){

  }

  ngOnInit(): void {
    this.fetchGroupAccounts();
  }

  searchValueChange(event: any){
    this.fetchGroupAccounts(event);
  }

  rowLengthChange(event: any){

  }

  pageChange(event: any){

  }

  async fetchGroupAccounts(search?: string){
    this.isLoading = true;
    try {
       const response = await this.httpService.post('/accounts/fetchGroupAccountData', {...(search && {search})});
       if(!response?.success){
          this.presentToast('Error', 'error', response?.message || 'Something went wrong');
          return;
       }
        const { data } = response;
        this.dataCount = data.length;
        data.forEach((item: any) => {
          item['redirect'] = 'https://'+item['domain_name']+'.iamneo.in';
        })
        this.dataList = data;
    } catch (error) {
      this.presentToast('Error', 'error', 'Something went wrong');
    }
    finally{
      this.isLoading = false;
    }
  }

  navigateToCreateAcc(){
    this.router.navigate(['groupCreation/create-account']); 
  }

  presentToast(message: string, type: string, detail: string) {
    this.messageService.add({
      severity: type,
      summary: message,
      detail: detail,
    });
  }

  async handleRedirect(event: any){
    const { group_account_id, school_name , PWA , domain_name} = event;

    const userData = JSON.parse(localStorage.getItem('user_details') || '{}');
    userData['primary_email'] = userData['email'];
    userData['user_id'] = userData['super_admin_id'];
    userData['porfile_picture'] = userData['logo'];
    delete userData['logo'];
    delete userData['super_admin_id'];
    delete userData['email'];
    const payload: any = {
      group_account_id,
      school_name,
      PWA,
      domain_name,
      user_role: 'Super Admin',
      user_data: userData
    }
    this.isLoading = true;
    try {
      const res = await this.httpService.post('/redirectToken' , payload);
      if(!res?.success){
        this.presentToast('Error', 'error', 'Something went wrong');
        return;
      }
      const { data } = res;
      this.redirectToExternalUrl(data, domain_name);

    } catch (error) {
      this.presentToast('Error', 'error', 'Something went wrong');
    }
    finally{
      this.isLoading = false;
    }
  }

  redirectToExternalUrl(event: any, domain_name: string) {
    let baseUrl: string = `http://${domain_name}.ai/redirect`;
    baseUrl += `?data=${encodeURIComponent(JSON.stringify(event))}`;
    window.open(baseUrl, '_blank');
  }

  onRowClick(event: any){
    this.router.navigate([`groupCreation/${event.group_account_id}`]);
  }
}
