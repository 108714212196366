import { CollegesComponent } from './colleges/colleges.component';
import { CompaniesComponent } from './companies/companies.component';
import { JobTypesComponent } from './job-types/job-types.component';
import { FieldCreationComponent } from './field-creation/field-creation.component';
import { GlobalDataComponent } from './global-data/global-data.component';

import { FieldCreationTableComponent } from './field-creation-table/field-creation-table.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { ChipsModule } from 'primeng/chips';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';

// Custom Modules
import { AppRoutingModule } from './app-routing.module';

//custom Componenets
import { AppComponent } from './app.component';
import { PasswordModule } from 'primeng/password';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { CampusLoginTemplateComponent } from './campus-login/campus-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderComponent } from './header/header.component';
import { ProgrammeOfferedComponent } from './programme-offered/programme-offered.component';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { EditorModule } from 'primeng/editor';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableComponent } from './table/table.component';
import { TooltipModule } from 'primeng/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TabViewModule } from 'primeng/tabview';
import { SplitterModule } from 'primeng/splitter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';

// import { MyComponentComponent } from './my-component/my-component.component';
import { CollegeProfileComponent } from './college-profile/college-profile.component';
import { TabMenuComponent } from './tab-menu/tab-menu.component';
import { AccountCreateComponent } from './account-create/account-create.component';
import { DialogModule } from 'primeng/dialog';
import { AttributeCreationComponent } from './attribute-creation/attribute-creation.component';
import { GroupingComponent } from './grouping/grouping.component';
import { GroupCreationComponent } from './group-creation/group-creation.component';

@NgModule({
  declarations: [
    AppComponent,
    CampusLoginTemplateComponent,
    DashboardComponent,
    SideNavComponent,
    HeaderComponent,
    ProgrammeOfferedComponent,
    TableComponent,
    CollegeProfileComponent,
    TabMenuComponent,
    AccountCreateComponent,
    FieldCreationComponent,
    FieldCreationTableComponent,
    GlobalDataComponent,
    CollegesComponent,
    CompaniesComponent,
    JobTypesComponent,
    AttributeCreationComponent,
    GroupingComponent,
    GroupCreationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    GalleriaModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    CheckboxModule,
    PanelModule,
    InputTextModule,
    NgDynamicBreadcrumbModule,
    TableModule,
    SidebarModule,
    DropdownModule,
    DividerModule,
    MultiSelectModule,
    RadioButtonModule,
    AccordionModule,
    OverlayPanelModule,
    ToastModule,
    TooltipModule,
    NgxDropzoneModule,
    TabViewModule,
    EditorModule,
    SplitterModule,
    DialogModule,
    ChipsModule,
    ChipModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextareaModule,
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    RadioButtonModule,
    InputMaskModule,
  ],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent],
})
export class AppModule {}
