import { Component } from '@angular/core';
import { SuperAdminService } from '../services/super-admin.service';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-service.service';
import { HttpService } from '../services/http.service';

interface ListItem {
  value: string;
}

@Component({
  selector: 'app-job-types',
  templateUrl: './job-types.component.html',
  styleUrls: ['./job-types.component.css'],
  providers: [MessageService]
})
export class JobTypesComponent {
  constructor(private messageService: MessageService, private superAdminService: SuperAdminService, private globalService: GlobalServicesService, private http: HttpService) { }

  inputItems: any[] = [];
  showEditIcon: boolean[] = [];
  isLoading: boolean = false
  purpose: string = ""
  dataList: any[] = [];

  async ngOnInit() {
    this.inputItems = []
    this.isLoading = true;
    const response = await this.fetchJobTypes();
    this.isLoading = false;
    if (response.success) {
      this.dataList = response.data;
    }
    this.dataList.forEach(element => {
      element.saved = true;
      element.value = element.job_type;
      this.inputItems.push(element)
    });
  }
  addInput() {
    this.purpose = ''
    this.inputItems.push({ value: '', saved: false });
    this.purpose = "create"
    this.showEditIcon.push(false);
  }

  removeInput(index: number) {
    this.inputItems.splice(index, 1);
    this.purpose = '';
  }

  editItem(index: number) {
    this.purpose = ''
    this.inputItems[index].saved = false;
    this.purpose = "edit"
  }

  async saveItem(index: number) {
    const payload: any = {};
    if (this.purpose === 'create') {
      payload.job_type = this.inputItems[index].value
      this.isLoading = true
      const invite = await this.jobTypeImport(payload);
      this.isLoading = false
      if (invite && invite.success) {
        this.inputItems[index].saved = true;

        this.messageService.add({ severity: 'success', summary: 'Success', detail: invite.data })
        const response = await this.fetchJobTypes();
        this.dataList = [];
        this.inputItems = []; 
        if (response.success) {
          this.dataList = response.data;
        }
        this.dataList.forEach(element => {
          element.saved = true;
          element.value = element.job_type;
          this.inputItems.push(element)
        });
        this.purpose = ''

      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: invite.message })
      }
    }
    else if (this.purpose === 'edit') {
      payload.job_type_id = this.inputItems[index].job_type_id;
      payload.job_type = this.inputItems[index].value;
      this.isLoading = true
      const update = await this.jobTypeUpdate(payload);
      this.isLoading = false
      if (update && update.success) {
        this.inputItems[index].saved = true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: update.data })
        const response = await this.fetchJobTypes();
        this.dataList = [];
        this.inputItems = []; 
        if (response.success) {
          this.dataList = response.data;
        }
        this.dataList.forEach(element => {
          element.saved = true;
          element.value = element.job_type;
          this.inputItems.push(element)
        });
        this.purpose = ''
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: update.message })
      }
    }
    this.isLoading = false
  }

  async jobTypeImport(body: any) {
    const fetchProgrammeDetails = await this.http.post('/jobTypes/import', body);
    return fetchProgrammeDetails;
  }

  async fetchJobTypes() {
    const fetchProgrammeDetails = await this.http.get('/jobTypes/fetch');
    return fetchProgrammeDetails;
  }

  async jobTypeUpdate(body: any) {
    const fetchProgrammeDetails = await this.http.post('/jobTypes/update', body);
    return fetchProgrammeDetails;
  }

  async deleteJobTypes(body: any) {
    const fetchProgrammeDetails = await this.http.post('/jobTypes/delete', body);
    return fetchProgrammeDetails;
  }

  async deleteTemplate(jobType: any, index: any) {
    this.inputItems.splice(index, 1);
    const payload: any = {}
    this.isLoading = true;
    payload.job_type_id = jobType.job_type_id
    const deleteResponse = await this.deleteJobTypes(payload);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully Deleted' })
    const response = await this.fetchJobTypes();
    if (response.success) {
      this.dataList = response.data;
    }
    this.isLoading = false;
  }

  editTemplate(template: any) {

  }
}

