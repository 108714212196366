import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../app/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  api_url = environment.HOST_LINK;

  options() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
    });
    return { headers };
  }

  constructor(private http: HttpClient) {}

  public async putApicall(endpoint: any, payload: any): Promise<any> {
    return new Promise(async (resolved: any, rejected: any) => {
      this.http.put(`${this.api_url}${endpoint}`, payload).subscribe((res: any) => {
        resolved(res)
      })
    })
  }

  public async postApiCall(endpoint: any, payload: any): Promise<any> {
    return new Promise(async (resolved: any, rejected: any) => {
      this.http.post(`${this.api_url}${endpoint}`, payload).subscribe((res: any) => {
        resolved(res)
      })
    })
  }

  public async uploadUsingSignedUrl(endpoint: any, payload: any): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': payload.type,
      }),
      observe: 'response' as 'body',
    };

    try {
      const response = await firstValueFrom(this.http.put(endpoint, payload, httpOptions));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
