import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  iconsList: any[] = [];
  path: string = '';
  constructor(private router: Router, private location: Location) {
    this.iconsList = [
      { label: "Dashboard", value: 'dashboard', icon: 'dashboard', isActive: true },
      // { label: "accounts", value: 'Accounts' , icon: 'Group', isActive: false },
      // { label: "field-creation", value: 'Field Creation', icon: 'Group', isActive: false },
      { label: "Accounts", value: "groupCreation"  , icon: 'college', isActive: false },
      { label: "Global Data", value: "global-data" , icon: 'programme-mapping', isActive: false },
      // { label: "students", icon: 'Group', isActive: false },
    ]
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      this.path = this.location.path()
      if (event instanceof NavigationEnd) {
        let pathname = event.url.split('/')[1]
        this.iconsList.forEach((list: any) => {
          list.isActive = (list.value === pathname)
        })
      }
    })
  }

  navClick(option: any) {
    this.iconsList.forEach((list: any) => {
      list.isActive = (list.value === option.value);
      console.log(list.isActive, list.value)
    })

    console.log(option)
    this.router.navigateByUrl(option.value)
  }

}
