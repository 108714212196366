import { Component } from '@angular/core';
import { bannerPattern} from '@assets'
@Component({
  selector: 'app-global-data',
  templateUrl: './global-data.component.html',
  styleUrls: ['./global-data.component.css']
})
export class GlobalDataComponent {
  selectedTab: number | null = 1;
  bannerPattern = bannerPattern;
  tabs: string[] = [
    'Programme Offered',
    'Grouping',
    'Job Types',
    'Companies',
  ];


  // Function to handle mail description click
  toggleTabs(tabSelect: number) {
    this.selectedTab = tabSelect;
  }

}
