<div *ngIf="isLoading" class="{{isLoading ? 'loadingOverlay' : ''}}">
  <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
  </p-progressSpinner>
</div>

<div class="college-profile-cont">
  <div class="button-cont">
    <button pButton pRipple class="p-button-create blue-filled-btn" (click)="createNew()" label="Create new">
    </button>
    <p-sidebar [(visible)]="createSidebarShow" (onHide)="onSidebarHide()" position="right"
      class="college-profile-side-bar" id="college-profile-side-bar">
      <app-account-create #accountCreate [createSidebarShow]="createSidebarShow" [patchValue]="patchedValues"
        (closeSideBar)="sideBarClose($event)"></app-account-create>
    </p-sidebar>
  </div>
</div>
<div class="table-cont table-relative">
  <div class="table-cont table-relative">
    <app-table [columns]="columns" [dataList]="dataList" [dataCount]="dataCount" [pageLimits]="pageLimits"
      [actionOptions]="actionOptions" [filterData]="filterData" [totalPage]="totalPage" [checkBox]="true"
      [allRowsSelected]="checkedAll" (openEdit)="openEditFiled($event)" (search)="searchValueChange($event)"
      (rowLength)="rowLengthChange($event)" (pageNo)="pageChange($event)" [pageAndRow]="pageAndRow"
      (openSideBar)="openAccountDetails($event)"></app-table>
    <p-toast></p-toast>
  </div>
  <p-toast></p-toast>
</div>