<div class="sidebar">
    <div>
      <img class="sidebar-sourceImg" src="../assets/side-nav/neo-exam-icon.svg" alt="" />
    </div>
    <div class="sidebar-icons">
      <ng-container *ngFor="let icons of iconsList">
        <button class="icon-fun {{icons.isActive ? 'active' : ''}}" [pTooltip]="icons.label" tooltipPosition="left" (click)="navClick(icons)">
            <img src="../assets/side-nav/{{icons.icon}}.svg" alt="" title="{{icons.label}}">
        </button>
      </ng-container>
    </div>
  </div>        