<div class="container">
  <!-- Middle column with mail descriptions -->
  <!-- <div class="column1">
    <div *ngFor="let tab of tabs; let i = index" (click)="toggleTabs(i + 1)" class="mail-description"
      [class.selected]="selectedTab === i + 1">
      {{ tab }}
    </div>
  </div> -->

  <!-- Right column with mail content -->
  <!-- <div class="column2">
    <div *ngIf="selectedTab === 1" class="mail-content">
      <app-programme-offered></app-programme-offered>
    </div>
    <div *ngIf="selectedTab === 2" class="mail-content">
      <app-grouping></app-grouping>
    </div>
    <div *ngIf="selectedTab === 3" class="mail-content">
      <app-job-types></app-job-types>
    </div>
    <div *ngIf="selectedTab === 4" class="mail-content">
      <app-companies></app-companies>
    </div>
    <div *ngIf="selectedTab === 5" class="mail-content">
      <app-colleges></app-colleges>
    </div>
  </div> -->
  <div class="body-top-pattern" [ngStyle]="{'background-image': 'url(' + bannerPattern + ')'}">
      <div class="body-top-pattern-container">
        <span class="drive-name"> Global Data </span>
      </div>
  </div>
  <div class="header-body-all">
    <div class="global_data_details">
      <div class="global_data_info">
        <div class="global_data_background">
          <ng-container *ngFor="let tab of tabs;let i = index">
            <div class="info-list" [ngClass]="{'selectedInfo': selectedTab === i + 1}" (click)="toggleTabs(i + 1)">
              <span>{{ tab }}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="global_data_body_container">
        <div *ngIf="selectedTab === 1" class="mail-content">
          <app-programme-offered></app-programme-offered>
        </div>
        <div *ngIf="selectedTab === 2" class="mail-content">
          <app-grouping></app-grouping>
        </div>
        <div *ngIf="selectedTab === 3" class="mail-content">
          <app-job-types></app-job-types>
        </div>
        <div *ngIf="selectedTab === 4" class="mail-content">
          <app-companies></app-companies>
        </div>
        <div *ngIf="selectedTab === 5" class="mail-content">
          <app-colleges></app-colleges>
        </div>
      </div>
    </div>
  </div>
</div>